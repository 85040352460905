/* eslint-disable no-useless-return */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-undef */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';

import Pagination from '~/components/pagination';
import CreditTableUtil, { ProposalStatus } from '~/utils/proposal-status';
import SubTitleCT from '../components/SubTitle';
import TableList from '~/components/tableList';

import useApi, { ApiMethod } from '~/hooks/useApi';

import { cpfMask, IDefaultReturn } from '~/utils/masks';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import Endpoints from '~/services/endpoints';
import { icons } from '~/assets';
import ConsultProposalPaginationResponse from '~/services/credit-table/proposal/consult-pagination/response';
import Loading from '~/components/loading';

import * as St from './styles';
import ConsultProposalResponseNameCpf from '~/services/credit-table/proposal/consultNameCpf/response';
import { IListColCampaing } from '~/views/LimitIncrease/model';
import Container from '~/components/container';

interface IForm {
  nome: string;
  cpf: IDefaultReturn;
}

type StatusType = 'APROVADA' | 'REPROVADA' | 'PENDENTE' | 'ANALISE';
type SituationType = 'P2' | 'MESA_CREDITO';
type SituationTypeBtn = 'PENDENTE_ANALISE' | 'MESA_CREDITO';

interface IPaginationFilter {
  origem: null | string;
  situacao: null | SituationType;
  situationType: null | SituationTypeBtn;
  status: null | StatusType;
  campoOrdenacao: null | string;
}

const ConsultProposalCT = (): JSX.Element => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const [list, setList] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isFilterResult, setIsFilterResult] = useState(false);
  const [limitePorPagina, setLimitePorPagina] = useState(20);
  const [clearFilter, setClearFilter] = useState(false);
  const [ascDesc, setAscDesc] = useState('ASC');
  const [loading, setLoading] = useState(false);
  const [paginationFilter, setPaginationFilter] = useState({
    origem: null,
    situacao: null,
    situationType: null,
    status: null,
    campoOrdenacao: 'dataAnalise',
  } as IPaginationFilter);
  const firstUpdate = useRef(true);

  const history = useHistory();

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else if (clearFilter) {
      return;
    } else {
      searchStrategy();
    }
  }, [paginationFilter]);

  useEffect(() => {
    if (clearFilter) {
      setClearFilter(false);
      setList([]);
    }
  }, [list, clearFilter]);

  const handleGenerateURL = useMemo(() => {
    if (form?.cpf?.unmasked && form?.nome) {
      return `${Endpoints.consult.searchProposalNameCPFFilter}?cpf=${
        form?.cpf?.unmasked
      }&nome=${form?.nome}&limitePorPagina=${200}&pagina=${1}`;
    }
    if (form?.cpf?.unmasked && (!form?.nome || form?.nome === '')) {
      return `${Endpoints.consult.searchProposalNameCPFFilter}?cpf=${
        form?.cpf?.unmasked
      }&limitePorPagina=${200}&pagina=${1}`;
    }
    if (form?.nome && (!form?.cpf?.unmasked || form?.cpf?.unmasked === '')) {
      return `${Endpoints.consult.searchProposalNameCPFFilter}?nome=${
        form?.nome
      }&limitePorPagina=${200}&pagina=${1}`;
    }
    return `${Endpoints.consult.searchProposalNameCPFFilter}`;
  }, [form.cpf, form.nome, limitePorPagina, loading]);

  const RequestProposalFilter = useApi<ConsultProposalResponseNameCpf>(
    Endpoints.consult.searchProposalNameCPFFilter,
    ApiMethod.POST,
  );

  const RequestProposalPagination = useApi<ConsultProposalPaginationResponse>(
    Endpoints.consult.searchProposalPaginationNameCPF,
    ApiMethod.POST,
  );

  const RequestProposal = useApi<any>(
    Endpoints.consult.searchProposalNameCPFFilter,
    ApiMethod.POST,
  );

  const searchStrategy = useCallback(() => {
    if (form.cpf?.masked || form?.nome) {
      searchFilter();
    } else {
      searchPagination();
    }
  }, [paginationFilter]);

  const verifyStatusCode = (status: string) => {
    if (status.includes(ProposalStatus.ANALISE)) {
      return CreditTableUtil.ProposalStatusColor.ANALISE;
    }

    if (status.includes(ProposalStatus.ANALISADA)) {
      return CreditTableUtil.ProposalStatusColor.ANALISADA;
    }

    if (status.includes(ProposalStatus.PENDENTE_ANALISE)) {
      return CreditTableUtil.ProposalStatusColor.PENDENTE_ANALISE;
    }

    if (status.includes(ProposalStatus.PENDENTE_MESA)) {
      return CreditTableUtil.ProposalStatusColor.PENDENTE_MESA;
    }

    if (status.includes(ProposalStatus.APROVADA)) {
      return CreditTableUtil.ProposalStatusColor.APROVADA;
    }

    if (status.includes(ProposalStatus.REPROVADA)) {
      return CreditTableUtil.ProposalStatusColor.REPROVADA;
    }
  };

  const customerHeader = [
    {
      id: 1,
      maxChar: 5,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Nome completo',
      maxChar: 35,
      column: 'nomeCliente',
    },
    {
      id: 3,
      value: 'CPF',
      maxChar: 25,
      column: 'cpf',
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Status',
      maxChar: 25,
      column: 'status',
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Duração',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Data e Horário',
      maxChar: 30,
      column: 'dataAnalise',
      textAlign: 'center',
    },
    {
      id: 7,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const handleItemsStructure = (
    items: ConsultProposalPaginationResponse['pagina']['propostasResponse'],
    filter: Boolean,
  ) => {
    const newList = [];

    items?.forEach((data) => {
      if (
        !filter &&
        data?.status !== 'CANCELADA - MESA_CREDITO' &&
        data?.status !== 'CANCELADA - P2'
      ) {
        newList.push(makeList(data));
      } else {
        newList.push(makeList(data));
      }
    });

    setList(newList);
  };

  function makeList(data: any) {
    const cpfRet = cpfMask(data.cpf);
    const statusCode = verifyStatusCode(data.status);

    const item: IListColCampaing[] = [
      {
        type: 'icon',
        valueColor: CreditTableUtil.getStatusColor(statusCode),
        icon: (() => {
          if (
            data.origem === 'LOJA' ||
            data.origem === 'TOTEM' ||
            data.origem === 'SENFF'
          )
            return icons.shop;
          if (data.origem === 'APP' || data.origem === 'SITE') return icons.web;
        })(),
        textAlign: 'center',
      },
      {
        border: true,
        title: 'Nome completo',
        value: data.nomeCliente,
        maxChar: 35,
      },
      {
        border: true,
        title: 'CPF',
        value: cpfRet.masked,
        maxChar: 25,
        textAlign: 'center',
      },
      {
        title: 'Status',
        value: data.status,
        maxChar: 25,
        textAlign: 'center',
      },
      {
        title: 'Duração',
        value: data.duracao,
        maxChar: 20,
        textAlign: 'center',
      },
      {
        title: 'Data e Horário',
        value:
          data.dataAnalise && data.dataAnalise !== ''
            ? moment(data.dataAnalise).format('DD/MM/YYYY HH:mm:ss')
            : '',
        maxChar: 22,
        textAlign: 'center',
      },
      {
        item: data,
        searchAction: () => goToDetail(data.id, data.status),
        textAlign: 'center',
      },
    ];

    return item;
  }

  const searchPagination = async (page = 1) => {
    const response = await RequestProposalPagination.callApi({
      limitePorPagina: 20,
      pagina: page,
      origem: paginationFilter.origem,
      situacao: paginationFilter.situacao,
      status: paginationFilter.status,
      ordenacao: ascDesc,
      campoOrdenacao: paginationFilter.campoOrdenacao,
    });

    if (response.status === 200) {
      setTotalPages(response.data?.totalDePaginas);
      setTotalItems(response.data?.totalItens);
      handleItemsStructure(response.data?.pagina?.propostasResponse, false);
      setShowPagination(true);
      setIsFilterResult(false);
    }
  };

  const searchFilter = async (pagina = 1) => {
    const params = {
      pagina,
      ordenacao: ascDesc,
      campoOrdenacao: paginationFilter.campoOrdenacao,
      origem: paginationFilter.origem,
      situacao: paginationFilter.situacao,
      status: paginationFilter.status,
      cpf: form?.cpf?.unmasked,
      nome: form?.nome,
      limitePorPagina,
    };
    setLoading(true);
    try {
      const resp = await RequestProposal.callApi(params);

      if (resp.status === 200) {
        setTotalPages(resp?.data?.totalDePaginas);
        setTotalItems(resp.data?.totalItens);
        handleItemsStructure(resp?.data?.pagina?.buscaPropostaResponses, true);
        setShowPagination(true);
        setIsFilterResult(true);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };
  const goToDetail = (id, status) =>
    history.push('/credit-table/proposal/detail', { id, status });
  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Loading status={loading || RequestProposalPagination.loading} />
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="Nome"
                name="nome"
                value={form.nome}
                onChange={handleChange}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name="cpf"
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
                value={form.cpf?.masked}
                onChange={handleChange}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                disabled={
                  RequestProposalFilter.loading ||
                  RequestProposalPagination.loading ||
                  (form.cpf?.unmasked?.length !== 11 && !form.nome)
                }
                fitContent
                onClick={() => {
                  setPaginationFilter({
                    origem: null,
                    status: null,
                    situacao: null,
                    situationType: null,
                    campoOrdenacao: 'dataAnalise',
                  });
                }}
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row padding={16}>
            <ContainerFlex row flex={10}>
              <ContainerFlex
                row
                spaceAround
                align="center"
                flex={2}
                border="1px solid #c7c7c7"
                borderRadius={4}
                maxWidth={23}
                padding={5}
              >
                <ContainerFlex
                  marginBottom={2}
                  opacity={
                    paginationFilter.origem === 'LOJA,TOTEM' ? '0.5' : null
                  }
                  color={
                    paginationFilter.origem === 'LOJA,TOTEM' ? '#c4c4c4' : null
                  }
                  borderRadius={4}
                  padding={5}
                >
                  <SubTitleCT
                    value="LOJA/TOTEM"
                    icon={icons.shop}
                    onClick={() =>
                      setPaginationFilter((prev) => ({
                        ...prev,
                        origem: 'LOJA,TOTEM',
                        situationType: null,
                      }))
                    }
                  />
                </ContainerFlex>
                <ContainerFlex
                  marginBottom={2}
                  opacity={
                    paginationFilter.origem === 'APP,SITE' ? '0.5' : null
                  }
                  color={
                    paginationFilter.origem === 'APP,SITE' ? '#c4c4c4' : null
                  }
                  borderRadius={4}
                  padding={5}
                >
                  <SubTitleCT
                    onClick={() => {
                      setPaginationFilter((prev) => ({
                        ...prev,
                        origem: 'APP,SITE',
                        situationType: null,
                      }));
                    }}
                    value="APP/WEB"
                    icon={icons.web}
                  />
                </ContainerFlex>
              </ContainerFlex>
              <ContainerFlex
                row
                spaceAround
                align="center"
                border="1px solid #c7c7c7"
                borderRadius={4}
                maxWidth={30}
                marginLeft={5}
                padding={5}
              >
                <ContainerFlex
                  opacity={
                    paginationFilter.status === 'APROVADA' ? '0.5' : null
                  }
                  color={
                    paginationFilter.status === 'APROVADA' ? '#c4c4c4' : null
                  }
                  borderRadius={4}
                  marginBottom={2}
                  maxWidth={35}
                  padding={5}
                >
                  <SubTitleCT
                    value="APROVADA"
                    color={CreditTableUtil.getStatusColor(
                      CreditTableUtil.ProposalStatusColor.APROVADA,
                    )}
                    onClick={() =>
                      setPaginationFilter((prev) => ({
                        ...prev,
                        status: 'APROVADA',
                        situationType: null,
                      }))
                    }
                  />
                </ContainerFlex>
                <ContainerFlex
                  opacity={
                    paginationFilter.status === 'REPROVADA' ? '0.5' : null
                  }
                  color={
                    paginationFilter.status === 'REPROVADA' ? '#c4c4c4' : null
                  }
                  borderRadius={4}
                  marginBottom={2}
                  maxWidth={35}
                  padding={5}
                >
                  <SubTitleCT
                    value="REPROVADA"
                    color={CreditTableUtil.getStatusColor(
                      CreditTableUtil.ProposalStatusColor.REPROVADA,
                    )}
                    onClick={() =>
                      setPaginationFilter((prev) => ({
                        ...prev,
                        status: 'REPROVADA',
                        situationType: null,
                      }))
                    }
                  />
                </ContainerFlex>
              </ContainerFlex>
              <ContainerFlex
                row
                spaceAround
                align="center"
                border="1px solid #c7c7c7"
                borderRadius={4}
                maxWidth={50}
                marginLeft={5}
                padding={5}
              >
                <ContainerFlex
                  opacity={paginationFilter.status === 'ANALISE' ? '0.5' : null}
                  color={
                    paginationFilter.status === 'ANALISE' ? '#c4c4c4' : null
                  }
                  borderRadius={4}
                  marginBottom={2}
                  maxWidth={25}
                  padding={5}
                >
                  <SubTitleCT
                    value="ANÁLISE"
                    color={CreditTableUtil.getStatusColor(
                      CreditTableUtil.ProposalStatusColor.ANALISE,
                    )}
                    onClick={() =>
                      setPaginationFilter((prev) => ({
                        ...prev,
                        status: 'ANALISE',
                        situationType: null,
                        situacao: null,
                      }))
                    }
                  />
                </ContainerFlex>
                <ContainerFlex
                  opacity={
                    paginationFilter.situationType === 'PENDENTE_ANALISE'
                      ? '0.5'
                      : null
                  }
                  color={
                    paginationFilter.situationType === 'PENDENTE_ANALISE'
                      ? '#c4c4c4'
                      : null
                  }
                  borderRadius={4}
                  marginBottom={2}
                  maxWidth={45}
                  padding={5}
                >
                  <SubTitleCT
                    value="PEND. ANÁLISE"
                    color={CreditTableUtil.getStatusColor(
                      CreditTableUtil.ProposalStatusColor.PENDENTE_ANALISE,
                    )}
                    onClick={() =>
                      setPaginationFilter((prev) => ({
                        ...prev,
                        // origem: null,
                        status: 'PENDENTE',
                        situacao: 'P2',
                        situationType: 'PENDENTE_ANALISE',
                      }))
                    }
                  />
                </ContainerFlex>
                <ContainerFlex
                  opacity={
                    paginationFilter.situationType === 'MESA_CREDITO'
                      ? '0.5'
                      : null
                  }
                  color={
                    paginationFilter.situationType === 'MESA_CREDITO'
                      ? '#c4c4c4'
                      : null
                  }
                  borderRadius={4}
                  marginBottom={2}
                  maxWidth={30}
                  padding={5}
                >
                  <SubTitleCT
                    value="PEND. MESA"
                    color={CreditTableUtil.getStatusColor(
                      CreditTableUtil.ProposalStatusColor.PENDENTE_MESA,
                    )}
                    onClick={() =>
                      setPaginationFilter((prev) => ({
                        ...prev,
                        // origem: null,
                        status: 'PENDENTE',
                        situacao: 'MESA_CREDITO',
                        situationType: 'MESA_CREDITO',
                      }))
                    }
                  />
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
            {/* <St.BtnOrder
              onClick={() => {
                setPaginationFilter((prev) => ({ ...prev }));
                setAscDesc((prev) => {
                  if (prev === '' || prev === 'DESC') {
                    return 'ASC';
                  }
                  if (prev === 'ASC') {
                    return 'DESC';
                  }
                });
              }}
            >
              <img alt="" src={switchImage} />
            </St.BtnOrder> */}
            <St.BtnTrashCan
              onClick={() => {
                setClearFilter(true);
                setAscDesc('ASC');
                setTotalItems(0);
                setForm({ nome: '', cpf: { masked: '', unmasked: '' } });
                if (
                  paginationFilter.origem !== null ||
                  paginationFilter.situacao !== null ||
                  paginationFilter.situationType !== null ||
                  paginationFilter.status !== null
                ) {
                  setPaginationFilter({
                    origem: null,
                    status: null,
                    situacao: null,
                    situationType: null,
                    campoOrdenacao: 'dataAnalise',
                  });
                }
              }}
            >
              Limpar filtro
            </St.BtnTrashCan>
          </ContainerFlex>
        </St.BoxCard>

        {list.length <= 0 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <TitleCategory>Nenhuma proposta encontrada.</TitleCategory>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex padding={16}>
              {list.length > 0 && (
                <>
                  <ContainerFlex row>
                    <ContainerFlex>
                      <span style={{ fontWeight: 'bold' }}>
                        {`Resultado: ${totalItems}`}
                      </span>
                    </ContainerFlex>
                  </ContainerFlex>
                  <TableList
                    customerHeader={customerHeader}
                    list={list}
                    orderBy={ascDesc}
                    ordenationField={paginationFilter.campoOrdenacao}
                    onSubmitOrder={(e) => {
                      setPaginationFilter((prev) => ({
                        ...prev,
                        campoOrdenacao: e.column,
                      }));
                      setAscDesc(e.order);
                    }}
                  />
                </>
              )}

              {showPagination && list.length > 0 && (
                <ContainerFlex row horizontal center marginTop={20}>
                  {isFilterResult ? (
                    <Pagination
                      totalPages={totalPages}
                      onCurrentPageChange={searchFilter}
                    />
                  ) : (
                    <Pagination
                      totalPages={totalPages}
                      onCurrentPageChange={searchPagination}
                    />
                  )}
                </ContainerFlex>
              )}
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default ConsultProposalCT;
