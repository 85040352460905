/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable prefer-template */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import fileSize from 'filesize';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import CardView from '~/components/cartView';
import Collapse from '~/components/collapse';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import RadioButton from '~/components/radioButton';
import Select from '~/components/select';
import Pagination from '~/components/pagination';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Cartao from '~/models/cartao';
import Endereco from '~/models/endereco';
import {
  IDocumentTypes,
  IProposal,
  IProposalDocuments,
  IReference,
  IResponseGetDocumentLink,
  IResponseInformationKYC,
} from '~/models/proposal';
import { RELATION_TYPES } from '~/models/proposta-contatos-ref';
import CardBlockPayload from '~/services/card/block/payload';
import CardBlockResponse from '~/services/card/block/response';
import CardGetTokenPayload from '~/services/card/getToken/payload';
import Termos from '~/services/terms';
import CardGetTokenResponse from '~/services/card/getToken/response';
import CardReasonsForBlocking from '~/services/card/reasonsForBlocking/response';
import ConsultUserDetailResponse, {
  ConsultUserLimitHistory,
  ConsultUserDaysDelay,
} from '~/services/consult/user/detail/response';

import { SearchReasonsBlockResponse } from '~/services/consult/user/reasonsBlock/response';
import UpdateUserPayload from '~/services/consult/user/update/payload';
import Endpoints from '~/services/endpoints';
import { transformInGlobalDate, transformInNormalDate } from '~/utils/date';
import { downloadReadableStream, openInNewTab } from '~/utils/downloads';
import { FormField, setFormState } from '~/utils/form';
import * as Masks from '~/utils/masks';
import { cpfMask, IDefaultReturn } from '~/utils/masks';
import {
  dateValidator,
  emailValidator,
  IValidators,
  phoneValidator,
  socialNameValidator,
  ValidatorsNames,
} from '~/utils/validators';
import { CREDIT_CARDS, EXPIRATION_DAYS } from './constants';
import * as St from './styles';
import IResponseGetUserDocuments from '~/services/files/getUserDocuments/response';
import { CancelClientPayload } from '~/services/card/cancel/payload';
import Checkbox from '~/components/checkbox';
import Container from '~/components/container';
import DocumentsTable from '~/components/DocumentsTable';
import Constants from '~/utils/constants';
import {
  IResponseContractBase64,
  IResponseContractWithdraw,
} from '~/services/loan/loan';
import PasswordModal from '~/components/passwordModal';
import THEMES from '~/themes/themes';
import BenefitModal from '~/components/BenefitModal';
import { IResponseContractedsBenefits } from '~/services/sales/benefits/contracteds/response';
import IResponseDefaultError from '~/services/default-error/response';
import PictureCard from '~/components/pictureCard';

interface Form {
  id?: FormField;
  celular?: FormField;
  celular2?: FormField;
  dataNascimento?: FormField;
  email?: FormField;
  genero?: FormField;
  idClienteAutorizador?: FormField;
  idUsuario?: FormField;
  dataCadastro?: FormField;
  nome?: FormField;
  nomeSocial?: FormField;
  // endereço
  endBairro?: FormField;
  endCep?: FormField;
  endCidade?: FormField;
  endComplemento?: FormField;
  endLogradouro?: FormField;
  endNumero?: FormField;
  endPais?: FormField;
  endUf?: FormField;
  cardAnoExpiracao?: FormField;
  cardBandeira?: FormField;
  cardBloqueado?: FormField;
  cardCodigoSeguranca?: FormField;
  cardImpresso?: FormField;
  cardLimiteCredito?: FormField;
  cardMesExpiracao?: FormField;
  aposentadoPensionista?: boolean;
  cardNomeTitular?: FormField;
  cardNumeroCartao?: FormField;
  dataAtivacao?: FormField;
  cardStatus?: FormField;
  checkWhatsapp?: boolean;
  optinTermoDeUso?: boolean;
  origemOptin?: FormField;
  diaVencimento: FormField;
  melhorDiaCompra: FormField;
  profissao: FormField;
  nomeEmpresa: FormField;
  rendaInformada: FormField;
  dataEmissao: FormField;
  dataImpressao: FormField;
  dataAprovacao: FormField;
  dataCorte: FormField;
  saldo: FormField;
  temaCartao: FormField;
  lojaNome: FormField;
  emCobranca: FormField;
  propDataCadastro: FormField;
  propDigitador: FormField;
  propLojaDoCadastro: FormField;
  propOrigemDoCadastro: FormField;
  propNomeOperador: FormField;
  propStatusAprovacao: FormField;
  propStatus: FormField;
  dataCancelamento: FormField;
  statusCliente: FormField;
  readmissao: boolean;
  limiteSaque: FormField;
  tipoCanc: string;
  temCartaoCredito: boolean;
  temCarteiraDigital: boolean;
  temEmprestimo: boolean;
  nivel: string;
  quantidadeTotalPaga: number;
  quantidadeTotalParcial: number;
  quantidadeTotalParcelada: number;
  pontuacao: number;
  dataCampanha: string;
  categoriaCartao: FormField;
  carteira?: {
    cartoes: [
      {
        bandeira: string;
        blackList: boolean;
        numeroCartao: string;
        principal: boolean;
        validado: boolean;
        id: string;
      },
    ];
    dataAdesao: string;
    dataPrimeiraCompra: string;
    nomeOperador: string;
  };
  tipoDoc: string;
}

interface ProductsProps {
  filter: any;
  descricao: string;
  imagem: string;
  mensagem: string;
  nome: string;
  tipoProduto: string;
}

interface LocationState {
  cpf: string;
  numeroCartao: string;
  produtos: ProductsProps;
}

interface IDocumentsImage {
  income: {
    front: string;
    tipo?: string;
    nome?: string;
  };
  address: {
    front: string;
    tipo?: string;
    nome?: string;
  };
}

interface ICardDeliveryTermImage {
  term: {
    front: string;
    tipo?: string;
  };
}

interface IThemeCard {
  categoria: string;
  descricao?: string;
  descricaoTema: string;
  idExterno: string;
  link: string;
  modeloTema: string;
  urlImagem: string;
  valor: Number;
}

const ConsultUserDetail = (): JSX.Element => {
  const user = JSON.parse(localStorage.getItem('@NovaEra:user'));
  const [cpf] = useState<IDefaultReturn>(cpfMask(user?.cpf) as IDefaultReturn);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { state } = useLocation<{
    id: string;
    cpf: string;
    produtos: ProductsProps[];
  }>();
  const [modalPasswordOpen, setModalPasswordOpen] = useState<{
    open: boolean;
    type: 'twoWayCard' | 'blockCard' | 'cancelClient' | '';
  }>({ open: false, type: '' });

  const [dialogAskBlockCardShow, setDialogAskBlockCardShow] = useState(false);
  const [dialogErrorCancelCard, setDialogErrorCancelCard] = useState(false);
  const [dialogCancelCardShow, setDialogCancelCardShow] = useState(false);
  const [dialogDuplicateCard, setDialogDuplicateCard] = useState(false);
  const [dialogCancelCardIsOpen, setDialogCancelCardIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogIsOpenError, setDialogIsOpenError] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [dialogHistory, setDialogHistory] = useState(false);
  const [dialogLimitHistory, setDialogLimitHistory] = useState(false);
  const [dialogBlackListRemoveError, setDialogBlackListRemoveError] =
    useState(false);
  const [dialogLimitHistoryWithdraw, setDialogLimitHistoryWithdraw] =
    useState(false);
  const [dialogHistoryConsultKYC, setDialogHistoryConsultKYC] = useState(false);
  const [openDialogKYC, setOpenDialogKYC] = useState(false);
  const [dialogIsOpenSaveR, setDialogIsOpenSaveR] = useState(false);
  const [dialogMessageStatus, setDialogMessageStatus] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogWalletCards, setDialogWalletCards] = useState(false);
  const [dialogBlackListCards, setDialogBlackListCards] = useState(false);
  const [dialogUpdateLimit, setDialogUpdateLimit] = useState(false);
  const [errors, setErrors] = useState({} as IValidators);
  const [tax, setTax] = useState<boolean | null>(null);
  const [errorCardDesign, setErrorCardDesign] = useState('');
  const [cardDesign, setCardDesign] = useState('');
  const [daysDelay, setDaysDelay] = useState(0);
  const [proposalDocuments, setProposalDocuments] = useState([]);
  const [diverseDocuments, setDiverseDocuments] = useState([]);
  const [automaticLimit, setAutomaticLimit] = useState(false);
  const [senha, setSenha] = useState('');
  const [balanceError, setBalanceError] = useState('');
  const [isLoadingUpdate, setIsloadingUpdate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [cardInfos, setCardInfos] = useState<any>([]);
  const [addReference, setAddReference] = useState<IReference>({
    id: '',
    comentarioRef: '',
    nomeRefe: '',
    phoneRefe: { masked: '', unmasked: '' },
    relacaoCli: '',
  });

  const [addReferenceII, setAddReferenceII] = useState<IReference>({
    id: '',
    comentarioRef: '',
    nomeRefe: '',
    phoneRefe: { masked: '', unmasked: '' },
    relacaoCli: '',
  });
  const [cashBackPopUp, setCashBackPopUp] = useState(false);
  const [propostaId, setPropostaId] = useState('');
  const [reasonForBlocking, setReasonForBlocking] = useState(null);
  const [errorReasonBlock, setErrorReasonBlock] = useState<string | null>();
  const [errorTax, setErrorTax] = useState<string | null>();
  const [optionsForBlocking, setOptionsForBlocking] = useState([]);
  const [documentsImage, setDocumentsImage] = useState({} as IDocumentsImage);
  const [documents, setDocuments] = useState({} as IResponseGetUserDocuments);
  const [dialogDeleteDocumentOpen, setDialogDeleteDocumentOpen] =
    useState(false);
  const [standard, setStandard] = useState(false);
  const [dialogDocumentOpen, setDialogDocumentOpen] = useState(false);
  const [dialogDocumentMessage, setDialogDocumentMessage] = useState('');
  const [zeussBalance, setZeussBalance] = useState(0);
  const [zeussPhone, setZeussPhone] = useState('');
  const [selectedOption, setSelectedOption] = useState(0);
  const [openBenefitsModal, setOpenBenefitsModal] = useState(false);

  const urlContract = Endpoints.consult.userContract.replace(
    'PARAM_ID_PROPOSTA',
    propostaId,
  );

  const urlPlatinumContract = Endpoints.files.getPlatinumTerms.replace(
    'PARAM_ID_PROPOSTA',
    propostaId,
  );

  const [form, setForm] = useState<Form>({
    id: { name: 'id', value: '' },
    celular: { name: 'celular', value: '' },
    celular2: { name: 'celular2', value: '' },
    dataNascimento: { name: 'dataNascimento', value: '' },
    email: { name: 'email', value: '' },
    genero: { name: 'genero', value: '' },
    idClienteAutorizador: { name: 'idClienteAutorizador', value: '' },
    idUsuario: { name: 'idUsuario', value: '' },
    nome: { name: 'nome', value: '' },
    nomeSocial: { name: 'nomeSocial', value: '' },
    statusCliente: { name: 'statusCliente', value: '', status: '' },
    readmissao: false,
    tipoCanc: 'CANCELAMENTO_SOLICITACAO',

    endBairro: { name: 'endBairro', value: '' },
    endCep: { name: 'endCep', value: '' },
    endCidade: { name: 'endCidade', value: '' },
    endComplemento: { name: 'endComplemento', value: '' },
    endLogradouro: { name: 'endLogradouro', value: '' },
    endNumero: { name: 'endNumero', value: '' },
    endPais: { name: 'endPais', value: '' },
    endUf: { name: 'endUf', value: '' },
    origemOptin: { name: 'origemOptin', value: '' },
    dataCadastro: { name: 'dataCadastro', value: '' },

    cardAnoExpiracao: { name: 'cardAnoExpiracao', value: '' },
    cardBandeira: { name: 'cardBandeira', value: '' },
    cardBloqueado: { name: 'cardBloqueado', value: '' },
    dataCancelamento: { name: 'dataCancelamento', value: '' },
    cardCodigoSeguranca: { name: 'cardCodigoSeguranca', value: '' },
    cardImpresso: { name: 'cardImpresso', value: '' },
    cardLimiteCredito: { name: 'cardLimiteCredito', value: '' },
    cardMesExpiracao: { name: 'cardMesExpiracao', value: '' },
    cardNomeTitular: { name: 'cardNomeTitular', value: '' },
    cardNumeroCartao: { name: 'cardNumeroCartao', value: '' },
    cardStatus: { name: 'cardStatus', value: '' },
    dataAtivacao: { name: 'dataAtivacao', value: '' },
    categoriaCartao: { name: 'categoriaCartao', value: '' },

    diaVencimento: { name: 'diaVencimento', value: '' },
    melhorDiaCompra: { name: 'melhorDiaCompra', value: '' },
    profissao: { name: 'profissao', value: '' },
    nomeEmpresa: { name: 'nomeEmpresa', value: '' },
    rendaInformada: { name: 'rendaInformada', value: '' },
    dataEmissao: { name: 'dataEmissao', value: '' },
    dataImpressao: { name: 'dataImpressao', value: '' },
    dataAprovacao: { name: 'dataAprovacao', value: '' },
    dataCorte: { name: 'dataCorte', value: '' },
    lojaNome: { name: 'lojaNome', value: '' },

    propDataCadastro: { name: 'propDataCadastro', value: '' },
    propDigitador: { name: 'propDigitador', value: '' },
    propLojaDoCadastro: { name: 'propLojaDoCadastro', value: '' },
    propOrigemDoCadastro: { name: 'propOrigemDoCadastro', value: '' },
    propStatus: { name: 'propStatus', value: '' },
    propNomeOperador: { name: 'propNomeOperador', value: '' },
    propStatusAprovacao: { name: 'propStatusAprovacao', value: '' },
    saldo: { name: 'saldo', value: '' },
    temaCartao: { name: 'saldo', value: '' },
    emCobranca: { name: 'saldo', value: '' },
    limiteSaque: { name: 'limiteSaque', value: '' },
    temCartaoCredito: false,
    temCarteiraDigital: false,
    temEmprestimo: false,
    nivel: '',
    quantidadeTotalPaga: 0,
    quantidadeTotalParcial: 0,
    quantidadeTotalParcelada: 0,
    pontuacao: 0,
    dataCampanha: '',
    carteira: {
      cartoes: [
        {
          bandeira: '',
          blackList: false,
          numeroCartao: '',
          principal: false,
          validado: false,
          id: '',
        },
      ],
      dataAdesao: '',
      dataPrimeiraCompra: '',
      nomeOperador: '',
    },
    tipoDoc: '',
  });

  const isSenff = form.propOrigemDoCadastro.value === 'SENFF';
  const [previousDiaVencimento, setPreviousDiaVencimento] = useState('');

  const [limitHistoryData, setLimitHistoryData] = useState([]);
  // const [historyLoanConsultData, setHistoryLoanConsultData] = useState([]);
  const [totalPagesLimitHistory, setTotalPagesLimitHistory] = useState(0);

  const [cardDeliveryTerm, setCardDeliveryTerm] = useState(
    {} as ICardDeliveryTermImage,
  );
  const inputCardDeliveryTerm = useRef(null);
  const inputUploadDocument = useRef(null);

  const [dialogSizeFileMessage, setDialogSizeFileMessage] =
    useState<boolean>(false);
  // const [openDialogKYC, setOpenDialogKYC] = useState(false);
  const [sizeFile, setsizeFile] = useState<number>(0);
  const [benefitsDocuments, setBenefitsDocuments] = useState<any[]>([]);
  const [dataKYC, setDataKYC] = useState<IResponseInformationKYC>(
    {} as IResponseInformationKYC,
  );
  const [benefits, setBenefits] = useState([]);
  const [contractedWithdraw, setContractedWithdraw] = useState([]);
  const [fileBase64Withdraw, setFileBase64Withdraw] = useState('');

  const [modalUploadDocumentOpen, setModalUploadDocumentOpen] = useState(false);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState(null);
  const [cardNumber, setCardNumber] = useState<string>();
  const userRoles = localStorage.getItem(Constants.USER_ROLE);

  const AuthValidatePassword = useApi<any>(
    Endpoints.account.validateUserPassword,
    ApiMethod.POST,
  );

  const api = useApi<ConsultUserDetailResponse>(
    Endpoints.consult.detailUserWallet.replace(
      'PARAM_CPF',
      location.state?.cpf,
    ),
    ApiMethod.GET,
  );

  const consultLimitHistory = useApi<ConsultUserLimitHistory>(
    Endpoints.consult.getUserLimitHistory.replace(
      'PARAM_CPF',
      location.state?.cpf,
    ),
    ApiMethod.POST,
  );

  const consultDaysDelay = useApi<ConsultUserDaysDelay>(
    Endpoints.consult.getUserDaysDelay.replace(
      'PARAM_NUMERO_CARTAO',
      location.state?.numeroCartao,
    ),
    ApiMethod.GET,
  );

  const RequestUserContract = useApi(urlContract, ApiMethod.GET);

  const RequestUserPlatinumContract = useApi(
    urlPlatinumContract,
    ApiMethod.GET,
  );

  const apiBlockCard = useApi<CardBlockResponse>(
    Endpoints.card.block,
    ApiMethod.PUT,
  );

  const RequestGetContractedWithdraw = useApi<IResponseContractWithdraw>(
    Endpoints.loan.getContractWithdraw.replace('PARAM_CPF', location.state.cpf),
    ApiMethod.GET,
  );

  const showOptionsRule = () => {
    const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
    const result = userRoles.includes('ADM') || userRoles.includes('SUP');
    return result;
  };

  const GetContractedBenefits = useApi<
    IResponseContractedsBenefits & IResponseDefaultError
  >(
    `${Endpoints.sales.getContractedBenefits.replace(
      'PARAM_ID_PROPOSTA',
      api?.data?.propostaId,
    )}`,
    ApiMethod.GET,
  );

  const RequestGetPDFContractedWithdraw = useApi<IResponseContractBase64>(
    Endpoints.loan.getContractWithdrawBase64.replace(
      'PARAM_ID',
      contractedWithdraw[0]?.id,
    ),
    ApiMethod.GET,
  );

  const apiCancelClient = useApi<any>(
    Endpoints.consult.cancelCard.replace('PARAM_TIPO', form.tipoCanc),
    ApiMethod.POST,
  );

  const apiCardToken = useApi<CardGetTokenResponse>(
    Endpoints.card.getToken,
    ApiMethod.POST,
  );

  const RequestInvoiceExpiration = useApi(
    Endpoints.update.invoiceExpiration,
    ApiMethod.POST,
  );

  const RequestUserDocuments = useApi(
    `${Endpoints.consult.searchUserDocuments}${api?.data?.propostaId}`,
    ApiMethod.GET,
  );

  const RequestUserDiverseDocuments = useApi(
    `${Endpoints.files.getSearchDocBase64.replace(
      'PARAM_ID_PROPOSTA',
      api?.data?.propostaId,
    )}`,
    ApiMethod.GET,
  );

  const RequestUserDocumentsCPF = useApi<IResponseGetUserDocuments>(
    Endpoints.consult.searchUserDocumentsCPF.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const RequestConsultZeussBalance = useApi<any>(
    Endpoints.consult.getConsultZeussBalance.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const RequestConsultZeussPhone = useApi<any>(
    Endpoints.consult.getConsultZeussPhone.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const RequestUploadCardDeliveryTerm = useApi(
    Endpoints.files.uploadCardDeliveryTerm,
    ApiMethod.POST,
  );

  const RequestCardDeliveryTerm = useApi<{ termoEntregaBase64: string }>(
    Endpoints.consult.searchCardDeliveryTerm.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const RequestSearchHistoricalBenefitProposal = useApi<any>(
    Endpoints.consult.getSearchHistoricalBenefitProposal.replace(
      'PARAM_CPF',
      state?.cpf,
    ),
    ApiMethod.GET,
  );

  const apiUserUpdate = useApi(Endpoints.consult.updateClient, ApiMethod.PUT);

  const apiBlockCardReasons = useApi<CardReasonsForBlocking>(
    Endpoints.card.listOfReasonsForBlocking.replace(
      'PARAM_ORIGEM',
      'BACKOFFICE',
    ),
    ApiMethod.GET,
  );

  const POSTReferenceAPI = useApi<IProposal>(
    `${Endpoints.register.proposalReference}/${propostaId}`,
    ApiMethod.PUT,
  );

  const RequestHistoricCard = useApi<SearchReasonsBlockResponse>(
    Endpoints.consult.getCardHistory.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const POSTDuplicateCard = useApi(
    `${Endpoints.register.duplicateCard}`,
    ApiMethod.POST,
  );

  const GETDocumentTypes = useApi<IDocumentTypes>(
    `${Endpoints.files.getDocumentTypes}`,
    ApiMethod.GET,
  );

  const GETProposalDocuments = useApi<IProposalDocuments>(
    `${Endpoints.files.getProposalDocuments.replace('PARAM_CPF', state?.cpf)}`,
    ApiMethod.GET,
  );

  const GETThemeCardsInfo = useApi<IThemeCard>(
    `${Endpoints.card.getClientCurrentCategory.replace(
      'PARAM_CPF',
      state?.cpf,
    )}`,
    ApiMethod.GET,
  );

  const POSTProposalDocuments = useApi(
    `${Endpoints.files.postProposalDocuments}`,
    ApiMethod.POST,
  );

  const DELETEProposalDocuments = useApi(
    `${Endpoints.files.deleteProposalDocument
      .replace('PARAM_CPF', state?.cpf)
      .replace('PARAM_FILENAME', selectedDocumentFile?.nome)}`,
    ApiMethod.DELETE,
  );

  const POSTGetDocumentBase64 = useApi<IResponseGetDocumentLink>(
    Endpoints.files.postGetDoc64,
    ApiMethod.POST,
  );

  const getWithDrawLimit = useApi(
    Endpoints.consult.getWithDrawLimit.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );
  const getWithDrawLimitHistory = useApi(
    Endpoints.consult.getWithDrawLimitHistory.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );
  const getTotalLimit = useApi(
    Endpoints.consult.getTotalLimit.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );
  const getCampaignLimitIncrease = useApi(
    Endpoints.consult.getCampaignLimitIncrease.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );
  const putAutomaticLimit = useApi(
    Endpoints.update.updateAutomaticLimit,
    ApiMethod.PUT,
  );

  const PutRemoveBlackList = useApi<any>(
    Endpoints.report.putRemoveBlackList
      .replace('PARAM_ID', cardNumber)
      .replace('PARAM_OP', localStorage.getItem('USER_ID')),
    ApiMethod.PUT,
  );

  const GetInformationKYCClient = useApi<IResponseInformationKYC>(
    Endpoints.loan.getInformationKYCClient.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const DeleteRestritionKYC = useApi(
    Endpoints.loan.deleteRemoveRestritionKYC.replace('PARAM_CPF', state?.cpf),
    ApiMethod.DELETE,
  );

  const getInformationKYCClient = async () => {
    try {
      const response = await GetInformationKYCClient.callApi();

      const { data, status } = response;

      if (status >= 200 && status < 300) {
        setDataKYC(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeRestritionKYC = useCallback(async () => {
    setOpenDialogKYC(false);

    try {
      const { status, mensagem } = await AuthValidatePassword.callApi({
        cpf: cpf?.unmasked,
        senha,
      });
      setSenha('');

      if (status >= 200 && status <= 300) {
        const response = await DeleteRestritionKYC.callApi();

        if (response.status >= 200) {
          setPopUpMessage('Restrição removida com sucesso');
          setOpenDialog(true);
        } else {
          setPopUpErrorMessage(response.mensagem);
          setPopUpError(true);
        }
      } else {
        setPopUpErrorMessage(mensagem);
        setPopUpError(true);
      }
    } catch (error) {
      setPopUpErrorMessage(error.mensagem);
      setPopUpError(true);
    }
  }, [senha]);

  const removeBlacklist = async () => {
    try {
      const response = await PutRemoveBlackList.callApi();
      if (response.status >= 200 && response.status < 300) {
        setDialogBlackListCards(false);
        window.location.reload();
      } else {
        setDialogBlackListRemoveError(true);
        setDialogText('Falha ao remover o cartão selecionado da blackList!');
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const getLimitHistory = async (page = 1, limit = 10) => {
    const payload = {
      periodoInicial: '2021-01-01',
      periodoFinal: moment(new Date()).format('YYYY-MM-DD'),
      tipoAumento: 'TODOS',
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await consultLimitHistory.callApi(payload);
    await Promise.allSettled([
      getWithDrawLimit.callApi(),
      getWithDrawLimitHistory.callApi(),
      getTotalLimit.callApi(),
    ]);

    const { data } = response;

    if (response.status === 200) {
      setLimitHistoryData(data?.pagina);
      setTotalPagesLimitHistory(data?.totalDePaginas);
    }
  };

  const getRequestHistoricalBenefit = async () => {
    try {
      const response = await RequestSearchHistoricalBenefitProposal.callApi();
      if (response.status >= 200 && response.status < 300) {
        setBenefitsDocuments(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDaysDelay = useCallback(async () => {
    const response = await consultDaysDelay.callApi();

    const { data } = response;

    if (response.status === 200) {
      setDaysDelay(data.data?.dias_em_atraso);
    }
  }, [form.temCartaoCredito]);

  const handleCustomerBase = useCallback(async () => {
    const response = await getCampaignLimitIncrease.callApi();

    const { data }: any = response;

    if (response.status === 200) {
      setAutomaticLimit(data?.ativo);
    }
  }, []);

  const handleUpdateAutomaticLimit = useCallback(async () => {
    setDialogUpdateLimit(false);
    try {
      const { status, mensagem } = await AuthValidatePassword.callApi({
        cpf: cpf?.unmasked,
        senha,
      });
      setSenha('');

      if (status >= 200 && status <= 300) {
        await putAutomaticLimit.callApi({
          ativo: !automaticLimit,
          cpf: state?.cpf,
        });
        setPopUpMessage(
          `Limite automático para o cliente ${
            automaticLimit ? 'DESATIVADO' : 'ATIVADO'
          } com sucesso.`,
        );
        setOpenDialog(true);
      } else {
        setPopUpErrorMessage(mensagem);
        setPopUpError(true);
      }
    } catch (err) {
      setPopUpErrorMessage(err.mensagem);
      setPopUpError(true);
    }
  }, [senha, automaticLimit]);

  const openCardDropDownAction = useCallback(async () => {
    if (!daysDelay) {
      await getDaysDelay();
    }
    if (!limitHistoryData.length) {
      await getLimitHistory(1, 10);
    }

    await handleCustomerBase();
  }, [limitHistoryData, daysDelay]);

  const goDetail = () =>
    history.push('/consult/reactivate-user/detail', {
      numeroCartao: form.cardNumeroCartao.value,
      nome: form.nome.value,
      cpf: state?.cpf,
    });

  const goToHistoryLoanCelcoin = () => {
    history.push('/consult/user/history-loan', {
      cpf: state?.cpf,
    });
  };

  const handleUpdateInvoiceExpiration = async () => {
    const response = await RequestInvoiceExpiration.callApi({
      dia: form.diaVencimento.value,
      numeroCartao: form.cardNumeroCartao?.value,
      senha: '',
    });

    if (response.status === 201) {
      setDialogText('Data de vencimento atualizada com sucesso!');
      await api.callApi();
    } else {
      setDialogText(
        response.mensagem || 'Falha ao atualizar data de vencimento.',
      );
    }
    setDialogIsOpen(true);
  };
  const loadDataForBlockReasons = () => {
    const { data } = apiBlockCardReasons;

    const reasons = [];

    data.data?.map((e) =>
      reasons.push({
        id: e.idMotivo,
        value: e.descricao,
      }),
    );
    // id 11 é o motivo Cancel. Bloq. Backoffice
    const reasonsWithoutCancelBlockBackoffice = reasons.filter(
      (e) => e.id !== 11,
    );

    setOptionsForBlocking(reasonsWithoutCancelBlockBackoffice);
  };

  useEffect(() => {
    if (!dialogDuplicateCard) {
      setCardDesign('');
      setTax(null);
    } else if (
      form.temaCartao.value?.toString() === 'PLATINUM' &&
      dialogDuplicateCard
    ) {
      setCardDesign(form.temaCartao.value?.toString());
    }
  }, [dialogDuplicateCard]);

  useEffect(() => {
    if (apiBlockCardReasons.data) {
      loadDataForBlockReasons();
    }
  }, [apiBlockCardReasons.loading]);

  useEffect(() => {
    api.callApi();
  }, []);

  useEffect(() => {
    if (form) {
      setCardNumber(form?.carteira?.cartoes?.[0]?.id);
    }
  }, [form]);

  async function getContractWithdraw() {
    try {
      const response = await RequestGetContractedWithdraw.callApi();
      if (response && Array.isArray(response.data)) {
        setContractedWithdraw(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getInfoCards = async () => {
      try {
        const response = await GETThemeCardsInfo.callApi();
        if (response.status >= 200) {
          setCardInfos(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getInfoCards();
  }, []);

  const getZeussBalance = async () => {
    try {
      const response = await RequestConsultZeussBalance.callApi();
      if (response.status >= 200 && response.status < 300) {
        setZeussBalance(response.data?.saldo);
        setCashBackPopUp(true);
      } else if (response.status === 404) {
        setBalanceError(response.mensagem);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getZeussPhone = async () => {
    try {
      const response = await RequestConsultZeussPhone.callApi();
      if (response.status >= 200) {
        setZeussPhone(response.data?.celularZeuss);
        setCashBackPopUp(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (contractedWithdraw.length > 0) {
      const getContract = async () => {
        const response = await RequestGetPDFContractedWithdraw.callApi({});
        if (response.status >= 200) {
          const { contentPdf } = response.data;
          setFileBase64Withdraw(contentPdf);
        }
      };
      getContract();
    }
  }, [contractedWithdraw]);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (state?.cpf) {
        try {
          const response = await RequestUserDocumentsCPF.callApi();
          setDocuments(response.data);
        } catch (error) {
          console.error('Erro ao buscar documentos:', error);
        }
      }
    };

    fetchDocuments();
  }, []);

  const handleLoadClientDocuments = useCallback(async () => {
    // para nao carregar duas vezes ao abrir e fechar o dropdown
    if (GETDocumentTypes.data) return;

    const [proposalDocuments, userDocumentsCpf] = await Promise.all([
      GETProposalDocuments.callApi(),
      GETDocumentTypes.callApi(),
    ]);

    if (form.temCartaoCredito) {
      const [contractedBenefits, cardDeliveryTerm, diverseDocuments] =
        await Promise.all([
          RequestCardDeliveryTerm.callApi(),
          RequestUserDocuments.callApi(),
          RequestUserDiverseDocuments.callApi(),
        ]);

      const { termoEntregaBase64 } = cardDeliveryTerm.data;

      getRequestHistoricalBenefit();

      getContractWithdraw();
      if (diverseDocuments.data) {
        setDiverseDocuments(
          diverseDocuments?.data?.map((e) => ({ ...e, nome: e.filename })) ??
            [],
        );
      }
      setBenefits(contractedBenefits.data?.beneficios ?? []);

      isImageBase64(`data:image/png;base64, ${termoEntregaBase64}`).then(
        (data) => {
          setCardDeliveryTerm((prev) => ({
            ...prev,
            term: {
              front:
                !!termoEntregaBase64 &&
                `${data ? 'data:image/png;base64' : ''}, ${termoEntregaBase64}`,
              tipo: data ? 'image' : 'pdf',
            },
          }));
        },
      );
    }

    if (proposalDocuments.data) {
      setProposalDocuments(proposalDocuments?.data?.documentos ?? []);
    }
    if (documents) {
      const { documentoEndereco, documentoRenda } = documents;
      isImageBase64(`data:image/png;base64, ${documentoEndereco}`).then(
        (data) => {
          setDocumentsImage((prev) => ({
            ...prev,
            address: {
              front:
                !!documentoEndereco &&
                `${data ? 'data:image/png;base64' : ''}, ${documentoEndereco}`,
              tipo: data ? 'image' : 'pdf',
              nome: 'Comprovante de residência',
            },
          }));
        },
      );
      isImageBase64(`data:image/png;base64, ${documentoRenda}`).then((data) => {
        setDocumentsImage((prev) => ({
          ...prev,
          income: {
            front:
              !!documentoRenda &&
              `${data ? 'data:image/png;base64' : ''}, ${documentoRenda}`,
            tipo: data ? 'image' : 'pdf',
            nome: 'Comprovante de renda',
          },
        }));
      });
    }
  }, [
    GETProposalDocuments,
    RequestUserDocuments,
    RequestUserDocumentsCPF,
    GETDocumentTypes,
    RequestSearchHistoricalBenefitProposal,
    GetContractedBenefits,
    RequestCardDeliveryTerm,
    form.temCartaoCredito,
    api.data,
  ]);

  useEffect(() => {
    if (!api.loading && api.data) {
      loadData();
    }
  }, [api.loading]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (apiBlockCard.status === 204) {
        setDialogAskBlockCardShow(false);
        await api.callApi();
        setDialogText('Cartão bloqueado com sucesso');
        setDialogIsOpen(true);
        RequestHistoricCard.callApi();
      } else if (apiBlockCard.status === 404 || apiBlockCard.status === 406) {
        const res = apiBlockCard.data as CardBlockResponse;
        setDialogAskBlockCardShow(false);
        setDialogText(res.mensagem);
        setDialogIsOpen(true);
      } else if (apiBlockCard.status >= 500) {
        setDialogAskBlockCardShow(false);
        setDialogText(apiBlockCard.mensagem);
        setDialogIsOpen(true);
      }
      setLoading(false);
      if (apiCancelClient.status === 200) {
        setDialogCancelCardShow(false);
        setDialogText('Cliente cancelado com sucesso');
        setDialogCancelCardIsOpen(true);
      } else if (
        apiCancelClient.status === 404 ||
        apiCancelClient.status === 406 ||
        apiCancelClient.status >= 500
      ) {
        const res = apiCancelClient.data;
        setDialogCancelCardShow(false);
        setDialogText(res.mensagem);
        setDialogErrorCancelCard(true);
      }
    })();
  }, [apiBlockCard.loading || apiCancelClient.loading]);

  const getCardToken = async (cardNumber?: string) => {
    const card = location.state?.numeroCartao;
    const payloadGetToken: CardGetTokenPayload = {
      numeroCartao: cardNumber ?? card,
    };

    const response = await apiCardToken.callApi(payloadGetToken);

    if (response.status > 300) {
      setDialogAskBlockCardShow(false);
      setDialogText(response.mensagem);
      setDialogErrorCancelCard(true);
    }
  };

  const cancel = () => {
    const payloadCancel: CancelClientPayload = {
      cpf: location.state?.cpf,
    };

    apiCancelClient.callApi(payloadCancel);
  };

  const block = () => {
    if (!reasonForBlocking) {
      setErrorReasonBlock(
        'Erro ao bloquear cartão, escolha o motivo do bloqueio',
      );
      return;
    }
    setErrorReasonBlock(null);

    const res = apiCardToken.data as CardGetTokenResponse;

    const payloadBlock: CardBlockPayload = {
      idMotivo: reasonForBlocking,
      tokenCartao: res.tokenCartao,
    };

    apiBlockCard.callApi(payloadBlock);
  };

  const askOtherCard = () => {
    setDialogDuplicateCard(true);
    if (cardDesign === 'PLATINUM') {
      setCardDesign(form.temaCartao.value?.toString());
    }
    setErrorTax('');
    setErrorCardDesign('');
  };

  const goBack = () => history.goBack();

  const handleValidation = () => {
    let errors = [
      emailValidator(form.email.value?.trim()),
      dateValidator(form.dataNascimento.value, ValidatorsNames.dateBirth),
      phoneValidator(form.celular.value),
      socialNameValidator(form.nomeSocial.value, form.nome.value),
    ].reduce((memo, el) => ({ ...memo, ...el }), {});

    if (form.celular2.value.length) {
      errors = {
        ...errors,
        ...phoneValidator(form.celular2.value, ValidatorsNames.phone2),
      };
    }

    if (errors?.socialName) {
      setDialogIsOpenError(true);
      setDialogText(String(errors?.socialName));
      return;
    }

    setErrors(errors);
    const hasErrors =
      Object.values(errors).filter((a) => a !== null).length > 0;
    return hasErrors;
  };

  const [email, setEmail] = useState('');

  const updateClient = async () => {
    const notValid = handleValidation();
    if (notValid) return;

    const endereco: Endereco = {
      bairro: form.endBairro.value,
      cep: form.endCep.unmasked,
      cidade: form.endCidade.value,
      complemento: form.endComplemento.value,
      logradouro: form.endLogradouro.value,
      numero: form.endNumero.value,
      pais: form.endPais.value,
      uf: form.endUf.value,
    };

    const payload: UpdateUserPayload = {
      celular: form.celular.unmasked,
      celular2: form.celular2?.unmasked,
      email: form.email.value?.trim(),
      idCliente: form.id.value,
      nomeSocial: form?.nomeSocial?.value,
      endereco,
      profissao: form.profissao.value,
      nomeEmpresa: form.nomeEmpresa.value,
      dataNascimento: transformInGlobalDate(form.dataNascimento.value),
      genero: form.genero.value,
      rendaInformada: Masks.currencyUnmasked(form.rendaInformada.value),
    };

    const response = await apiUserUpdate.callApi(payload);

    if (response.status === 200) {
      setDialogText('Dados atualizado com sucesso!');
    } else {
      setDialogText(response?.mensagem || 'Falha ao atualizar os dados!');
    }
    setDialogIsOpen(true);
  };

  const [modalCanceledBenefit, setModalCanceledBenefit] = useState(false);
  const [benefitContractLinks, setBenefitContractLinks] = useState({
    linkContrato: '',
    linkContratoCancelado: '',
  });
  const handleOpenModalCanceledBenefit = (
    linkContrato,
    linkContratoCancelamento,
  ) => {
    setBenefitContractLinks({
      linkContrato,
      linkContratoCancelado: linkContratoCancelamento,
    });
    setModalCanceledBenefit(true);
  };
  const loadData = () => {
    const data = api.data as ConsultUserDetailResponse;

    let cartao = {} as Cartao;

    if (data.cartaoAtual && data?.cartoes?.length > 0) {
      data.cartoes.forEach((c) => {
        cartao = {
          anoExpiracao: c.anoExpiracao || '',
          bandeira: c.bandeira || '',
          bloqueado: c.bloqueado || false,
          codigoSeguranca: c.codigoSeguranca || '',
          impresso: c.impresso || false,
          limiteCredito: c.limiteCredito || 0,
          mesExpiracao: c.mesExpiracao || '',
          nomeTitular: c.nomeTitular || '',
          numeroCartao: c.numeroCartao || '',
          status: c.status || '',
          dataAtivacao: c.dataAtivacao || '',
          origemOptin: c.origemOptin || '',
          optinTermoDeUso: c.optinTermoDeUso,
          checkWhatsApp: c.checkWhatsApp,
        };
      });
    }

    const dateTransformed = transformInNormalDate(
      String(data?.dataNascimento) || '',
    );

    const cep = Masks.cepMask(data.endereco?.cep);
    const telefone = Masks.phoneMask(Masks.phoneUnmask(data.celular));
    const telefone2 = Masks.phoneMask(Masks.phoneUnmask(data.celular2));
    const dtNascimento = dateTransformed;

    const dtCancelamento = transformInNormalDate(
      data?.cartaoAtual?.dataCancelamento?.split('T')[0],
    );

    const obj: Form = {
      ...form,
      id: {
        ...form.celular,
        value: data.id,
        unmasked: data.id,
      },
      celular: {
        ...form.celular,
        value: telefone.masked,
        unmasked: telefone.unmasked,
      },
      celular2: {
        ...form.celular2,
        value: telefone2.masked,
        unmasked: telefone2.unmasked,
      },
      dataNascimento: {
        ...form.dataNascimento,
        value: dtNascimento.masked,
        unmasked: dtNascimento.unmasked,
      },
      email: { ...form.email, value: data.email },
      genero: { ...form.genero, value: data.genero },
      idClienteAutorizador: {
        ...form.idClienteAutorizador,
        value: data.idClienteAutorizador,
      },
      idUsuario: { ...form.idUsuario, value: data.idUsuario },
      nome: { ...form.nome, value: data.nome },
      nomeSocial: { ...form.nomeSocial, value: data.nomeSocial },
      endBairro: { ...form.endBairro, value: data.endereco?.bairro },
      endCep: {
        ...form.endCep,
        value: cep.masked,
        unmasked: cep.unmasked,
      },
      endCidade: { ...form.endCidade, value: data.endereco?.cidade },
      endComplemento: {
        ...form.endComplemento,
        value: data.endereco?.complemento,
      },
      endLogradouro: {
        ...form.endLogradouro,
        value: data.endereco?.logradouro,
      },
      endNumero: { ...form.endNumero, value: data.endereco?.numero },
      endPais: { ...form.endPais, value: data.endereco?.pais },
      endUf: { ...form.endUf, value: data.endereco?.uf },

      aposentadoPensionista: data.aposentadoPensionista,

      statusCliente: {
        ...form.statusCliente,
        value: data.cartaoAtual?.motivoBloqueio?.descricao,
        status: data.cartaoAtual?.motivoBloqueio?.status,
      },
      dataCancelamento: {
        ...form.dataCancelamento,
        value: dtCancelamento.masked,
        unmasked: dtCancelamento.unmasked,
      },
      readmissao: data?.readmissao,
      cardAnoExpiracao: {
        ...form.cardAnoExpiracao,
        value: cartao.anoExpiracao,
      },
      cardBandeira: { ...form.cardBandeira, value: cartao.bandeira },
      cardBloqueado: { ...form.cardBloqueado, value: String(cartao.bloqueado) },

      cardCodigoSeguranca: {
        ...form.cardCodigoSeguranca,
        value: cartao.codigoSeguranca,
      },
      cardImpresso: { ...form.cardImpresso, value: String(cartao.impresso) },
      cardLimiteCredito: {
        ...form.cardLimiteCredito,
        value: String(cartao.limiteCredito),
      },
      cardMesExpiracao: {
        ...form.cardMesExpiracao,
        value: cartao.mesExpiracao,
      },
      cardNomeTitular: { ...form.cardNomeTitular, value: cartao.nomeTitular },
      cardNumeroCartao: {
        ...form.cardNumeroCartao,
        value: cartao.numeroCartao,
      },
      checkWhatsapp: cartao.checkWhatsApp,
      optinTermoDeUso: cartao.optinTermoDeUso,
      cardStatus: { ...form.cardStatus, value: cartao.status },
      origemOptin: { ...form.origemOptin, value: cartao.origemOptin },
      diaVencimento: {
        ...form.diaVencimento,
        value: data.diaVencimento?.substring(0, 2),
      },
      melhorDiaCompra: { ...form.melhorDiaCompra, value: data.melhorDiaCompra },
      profissao: { ...form.profissao, value: data.profissao },
      nomeEmpresa: { ...form.nomeEmpresa, value: data.nomeEmpresa },
      rendaInformada: {
        ...form.rendaInformada,
        value: Masks.currencyMask(data.rendaInformada),
      },

      dataCadastro: { ...form.dataCadastro, value: data.dataCadastro },
      dataEmissao: { ...form.dataEmissao, value: data.dataEmissao },
      dataImpressao: {
        ...form.dataImpressao,
        value: data.cartaoAtual?.dataImpressao,
      },
      dataAprovacao: {
        ...form.dataAprovacao,
        value: data.proposta?.dataAprovacao,
      },

      dataAtivacao: {
        ...form.dataAtivacao,
        value: cartao.dataAtivacao,
      },

      dataCorte: {
        ...form.dataCorte,
        value: data.dataFechamento,
      },

      lojaNome: { ...form.lojaNome, value: data.lojaNome },
      propDataCadastro: {
        ...form.propDataCadastro,
        value: data.proposta?.dataCadastro,
      },
      propDigitador: { ...form.propDigitador, value: data.atendente?.nome },
      propLojaDoCadastro: {
        ...form.propLojaDoCadastro,
        value: data.proposta?.lojaDoCadastro,
      },
      propOrigemDoCadastro: {
        ...form.propOrigemDoCadastro,
        value: data.proposta?.origemDoCadastro,
      },
      propStatus: { ...form.propStatus, value: data.proposta?.status },
      propStatusAprovacao: {
        ...form.propStatusAprovacao,
        value: data?.proposta?.statusAprovacao,
      },
      propNomeOperador: {
        ...form.propNomeOperador,
        value: data?.proposta?.operador?.nome,
      },
      saldo: { ...form.saldo, value: data.saldo },
      temaCartao: { ...form.saldo, value: data.modeloTemaCartao },
      emCobranca: { ...form.saldo, value: data.diasAtraso },
      temCartaoCredito: state.produtos?.some(
        (item) => item.tipoProduto === 'TEUCARD',
      ),
      temCarteiraDigital: state.produtos?.some(
        (item) => item.tipoProduto === 'CARTEIRA_DIGITAL',
      ),
      temEmprestimo: state.produtos?.some(
        (item) => item.tipoProduto === 'EMPRESTIMO',
      ),

      nivel: data?.nivel,
      quantidadeTotalPaga: data?.quantidadeTotalPaga,
      quantidadeTotalParcial: data?.quantidadeTotalParcial,
      quantidadeTotalParcelada: data?.quantidadeTotalParcelada,
      pontuacao: data?.pontuacao,
      dataCampanha: data?.dataCampanha,
      categoriaCartao: { ...form.categoriaCartao, value: data.categoriaCartao },

      carteira: {
        cartoes: data?.carteira?.cartoes,
        dataAdesao: data?.carteira?.dataAdesao,
        dataPrimeiraCompra: data?.carteira?.dataPrimeiraCompra,
        nomeOperador: data?.carteira?.nomeOperador,
      },
    };

    setEmail(data.email);
    setForm(obj);
    setPreviousDiaVencimento(data.diaVencimento?.substring(0, 2));
    setPropostaId(data.propostaId);

    if (data.proposta?.contatos?.length) {
      data.proposta?.contatos?.forEach((item, index) => {
        if (index === 0) {
          setAddReference({
            id: item.id,
            comentarioRef: item.comentario,
            nomeRefe: item.nome,
            phoneRefe: {
              masked: Masks.phoneMask(item.telefone).masked,
              unmasked: item.telefone,
            },
            relacaoCli: item.relacao,
          });
        } else {
          setAddReferenceII({
            id: item.id,
            comentarioRef: item.comentario,
            nomeRefe: item.nome,
            phoneRefe: {
              masked: Masks.phoneMask(item.telefone).masked,
              unmasked: item.telefone,
            },
            relacaoCli: item.relacao,
          });
        }
      });
    }
  };

  const setReferenceAPI = async () => {
    const payloadRefI = {
      idContato: addReference.id ? addReference.id : undefined,
      comentario: addReference.comentarioRef,
      nome: addReference.nomeRefe,
      relacao: addReference.relacaoCli,
      telefone: addReference.phoneRefe?.unmasked,
    };

    const payloadRefII = {
      idContato: addReferenceII.id ? addReferenceII.id : undefined,
      comentario: addReferenceII.comentarioRef,
      nome: addReferenceII.nomeRefe,
      relacao: addReferenceII.relacaoCli,
      telefone: addReferenceII.phoneRefe?.unmasked,
    };

    if (payloadRefI.nome || payloadRefI.relacao || payloadRefI.telefone) {
      await POSTReferenceAPI.callApi([payloadRefI]);
    }

    if (payloadRefII.nome || payloadRefII.relacao || payloadRefII.telefone) {
      await POSTReferenceAPI.callApi([payloadRefII]);
    }
  };

  const addReferenceAPI = async () => {
    await setReferenceAPI();

    setDialogIsOpenSaveR(false);

    setDialogMessage('Referências alterada com sucesso!');
    setDialogMessageStatus(true);
  };

  const handleChangeReference = ({ target: { name, value } }) => {
    setAddReference({
      ...addReference,
      [name]: value,
    });
  };

  const handleSelectReference = (value, name) => {
    setAddReference({
      ...addReference,
      [name]: value,
    });
  };

  const handleTermsConditions = async () => {
    const filePath = Termos.termosCondicoes;

    window.open(filePath);
  };

  const handleTermsPrivacy = async () => {
    const filePath = Termos.termosPrivacidade;
    window.open(filePath);
  };

  const handleChangeReferenceII = ({ target: { name, value } }) => {
    setAddReferenceII({
      ...addReferenceII,
      [name]: value,
    });
  };

  useEffect(() => {
    if (form?.categoriaCartao.value) {
      if (form?.categoriaCartao.value !== 'PLATINUM') {
        setStandard(true);
      }
    }
  }, [form?.categoriaCartao.value]);

  const handleSelectReferenceII = (value, name) => {
    setAddReferenceII({
      ...addReferenceII,
      [name]: value,
    });
  };

  const handleChange = (name: string, value: string) => {
    setForm((prev) => ({ ...prev, [name]: { value } }));
  };

  async function handleClickDuplicateCard() {
    if (tax === null) return setErrorTax('Selecione uma opção');

    const response = await POSTDuplicateCard.callApi({
      numeroCartao: Masks.creditCardMask(form.cardNumeroCartao?.value).unmasked,
      taxa: tax,
      cpfOperador: user.cpf,
      temaDoCartao: cardDesign,
    });

    if (response.status === 200 || response.status === 204) {
      await api.callApi();

      setDialogText('Segunda via do cartão solicitada com sucesso!');
      setDialogDuplicateCard(false);
    } else {
      setDialogText(
        response.mensagem
          ? response.mensagem
          : 'Falha ao solicitar segunda via do cartão!',
      );
      setDialogDuplicateCard(false);
    }
    setDialogIsOpen(true);
  }

  const onClickDownloadContract = async () => {
    const responseContract = await RequestUserContract.callApi();
    if (responseContract.status === 200) {
      downloadReadableStream(
        responseContract.response,
        `${form.nome?.value} - ${Masks.cpfMask(location.state?.cpf).masked}`,
      );
    }
  };

  const onClickDownloadPlatinumContract = async () => {
    const responseContract = await RequestUserPlatinumContract.callApi();
    if (responseContract.status === 200) {
      downloadReadableStream(
        responseContract.response,
        `${form.nome?.value} - ${Masks.cpfMask(location.state?.cpf).masked}`,
      );
    }
  };

  const onClickViewPlatinumContract = async () => {
    const responseContract = await RequestUserPlatinumContract.callApi();
    if (responseContract.status === 200) {
      openInNewTab(responseContract.response);
    }
  };

  const onClickDownloadWavFile = async (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };
  const isImageBase64 = (str) =>
    new Promise((resolve) => {
      const img = new Image();
      img.addEventListener('error', () => resolve(false));
      img.addEventListener('load', () => resolve(/^data:.*;base64,/.test(str)));
      img.src = str;
    });

  const fileToBase64 = async (f: Blob): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const triggerFileCardDeliveryTermFrontPicker = () =>
    inputCardDeliveryTerm.current?.click();

  const downloadBase64Image = (url: string, name: string) => {
    const a = document.createElement('a');
    a.href = `data:image/png;base64, ${url}`;
    a.download = `${name}.png`;
    a.click();
  };

  const handleUploadFile = async () => {
    const base64 = (await fileToBase64(selectedDocumentFile)) as string;

    const { status, mensagem } = await POSTProposalDocuments.callApi({
      arquivo: base64.split(',')[1],
      idTipoDocumento: form.tipoDoc,
      cpf: Masks.cpfMask(location.state?.cpf).unmasked,
      mimeType: selectedDocumentFile.type,
    });
    setModalUploadDocumentOpen(false);
    if (status >= 200 && status <= 300) {
      setDialogDocumentOpen(true);
      setDialogDocumentMessage('Documento salvo com sucesso!');
      setSelectedDocumentFile(null);
    } else {
      setDialogDocumentOpen(true);
      setDialogDocumentMessage(
        mensagem ?? 'Houve um erro ao salvar documento!',
      );
      setSelectedDocumentFile(null);
    }
  };

  const fileSelected = async <
    T extends keyof ICardDeliveryTermImage,
    U extends keyof ICardDeliveryTermImage[T],
  >(
    e: React.ChangeEvent<HTMLInputElement>,
    key: T,
    side: U,
  ) => {
    const fileValue = e.target.files[0];

    if (fileValue.size > 1000000) {
      setDialogSizeFileMessage(true);
      setsizeFile(fileValue.size);
    } else if (fileValue) {
      const base64 = (await fileToBase64(fileValue)) as string;
      try {
        await RequestUploadCardDeliveryTerm.callApi({
          file: base64.split(',')[1],
          cpf: Masks.cpfMask(location.state?.cpf).unmasked,
        });

        isImageBase64(base64).then((data) => {
          setCardDeliveryTerm((prev) => ({
            ...prev,
            [key]: {
              ...prev[key],
              [side]: base64,
              tipo: data ? 'image' : 'pdf',
            },
          }));
        });
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  const handleDeleteFile = async () => {
    const { status, mensagem } = await DELETEProposalDocuments.callApi();
    setDialogDeleteDocumentOpen(false);
    if (status >= 200 && status <= 300) {
      setDialogMessage('Documento excluído com sucesso!');
      setDialogMessageStatus(true);
      await GETProposalDocuments.callApi().then((data) => {
        setProposalDocuments(data?.data?.documentos);
      });
    } else {
      setDialogMessage(mensagem ?? 'Houve um erro ao excluir documento!');
      setDialogMessageStatus(true);
    }
  };

  const handleDownloadDocument = useCallback(async (e) => {
    const { status, data, mensagem } = await POSTGetDocumentBase64.callApi({
      cpf: state.cpf,
      nomeArquivo: e?.nome,
    });
    if (status >= 200 && status <= 300) {
      switch (data?.mimeType) {
        case 'image/jpg':
          handleOpenImageInNewTab(`data:image/jpg;base64, ${data?.base64}`);
          return;
        case 'image/png':
          handleOpenImageInNewTab(`data:image/png;base64, ${data?.base64}`);
          return;
        case 'image/jpeg':
          handleOpenImageInNewTab(`data:image/jpeg;base64, ${data?.base64}`);
          return;
        default:
          handleOpenPdfInNewTab(data?.base64);
      }
    } else {
      setDialogMessage(mensagem ?? 'Houve um erro ao visualizar documento!');
      setDialogMessageStatus(true);
    }
  }, []);

  const handleOpenImageInNewTab = (url: string) => {
    const w = window.open('');
    const image = new Image();
    image.src = url;
    setTimeout(() => {
      w?.document && w?.document.write(image.outerHTML);
    }, 0);
  };

  function base64ToBlob(base64, type = 'application/octet-stream') {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type });
  }
  const handleOpenPdfInNewTab = (base64: string) => {
    const blob = base64ToBlob(base64, 'application/pdf');
    const url = URL.createObjectURL(blob);
    const pdfWindow = window.open('');
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='" + url + "'></iframe>",
    );
  };

  const handleGetOtherDocuments = () => {
    GETProposalDocuments.callApi().then((data) => {
      setProposalDocuments(data?.data?.documentos);
    });
  };

  const dataDocuments = useMemo(
    () => [
      {
        title: 'Documentos Pessoais',
        hidden: !form.temCartaoCredito,
        data: [
          {
            disabled: {
              download: !RequestUserDocuments.data?.documentoFrente,
              view: !RequestUserDocuments.data?.documentoFrente,
            },
            nome: 'CNH ou Carteira de Identidade (Frente)',
            onClickDownload: () =>
              downloadBase64Image(
                RequestUserDocuments.data?.documentoFrente,
                `documentoFrente ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(
                `data:image/png;base64, ${RequestUserDocuments.data?.documentoFrente}`,
              ),
          },
          {
            disabled: {
              download: !RequestUserDocuments.data?.documentoVerso,
              view: !RequestUserDocuments.data?.documentoVerso,
            },
            nome: 'CNH ou Carteira de Identidade (Verso)',
            onClickDownload: () =>
              downloadBase64Image(
                RequestUserDocuments.data?.documentoVerso,
                `documentoVerso ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(
                `data:image/png;base64, ${RequestUserDocuments.data?.documentoVerso}`,
              ),
          },
          {
            disabled: {
              download: !RequestUserDocuments.data?.fotoCertiface,
              view: !RequestUserDocuments.data?.fotoCertiface,
            },
            nome: 'Certiface',
            onClickDownload: () =>
              downloadBase64Image(
                RequestUserDocuments.data?.fotoCertiface,
                `fotoCertiface ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(
                `data:image/png;base64, ${RequestUserDocuments.data?.fotoCertiface}`,
              ),
          },
          {
            disabled: {
              download: !documentsImage.income?.front,
              view: !documentsImage.income?.front,
            },
            nome: 'Comprovante de renda',
            onClickDownload: () =>
              documentsImage.income?.tipo === 'image' &&
              downloadBase64Image(
                documentsImage.income?.front.replace(
                  'data:image/png;base64, ',
                  '',
                ),
                `comprovanteDeRenda ${form.nome.value}`,
              ),
            onClickView:
              documentsImage.income?.tipo === 'image'
                ? () => handleOpenImageInNewTab(documentsImage.income?.front)
                : () => handleOpenPdfInNewTab(documentsImage.income?.front),
          },
          {
            disabled: {
              download: !documentsImage.address?.front,
              view: !documentsImage.address?.front,
            },
            nome: 'Comprovante de Residência',
            onClickDownload: () =>
              documentsImage.address?.tipo === 'image' &&
              downloadBase64Image(
                documentsImage.address?.front.replace(
                  'data:image/png;base64, ',
                  '',
                ),
                `comprovanteDeResidencia ${form.nome.value}`,
              ),
            onClickView:
              documentsImage.address?.tipo === 'image'
                ? () => handleOpenImageInNewTab(documentsImage.address?.front)
                : () => handleOpenPdfInNewTab(documentsImage.address?.front),
          },
        ],
      },
      {
        title: 'Documentos Pessoais Carteira Digital',
        hidden: !form.temCarteiraDigital,
        data: [
          {
            disabled: {
              download: !RequestUserDocumentsCPF.data?.documentoFrente,
              view: !RequestUserDocumentsCPF.data?.documentoFrente,
            },
            nome: 'CNH ou Carteira de Identidade (Frente)',
            onClickDownload: () =>
              downloadBase64Image(
                RequestUserDocumentsCPF.data?.documentoFrente,
                `documentoFrente ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(
                `data:image/png;base64, ${RequestUserDocumentsCPF.data?.documentoFrente}`,
              ),
          },
          {
            disabled: {
              download: !RequestUserDocumentsCPF.data?.documentoVerso,
              view: !RequestUserDocumentsCPF.data?.documentoVerso,
            },
            nome: 'CNH ou Carteira de Identidade (Verso)',
            onClickDownload: () =>
              downloadBase64Image(
                RequestUserDocumentsCPF.data?.documentoVerso,
                `documentoVerso ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(
                `data:image/png;base64, ${RequestUserDocumentsCPF.data?.documentoVerso}`,
              ),
          },
          {
            disabled: {
              download: !RequestUserDocumentsCPF.data?.fotoCertiface,
              view: !RequestUserDocumentsCPF.data?.fotoCertiface,
            },
            nome: 'Certiface',
            onClickDownload: () =>
              downloadBase64Image(
                RequestUserDocumentsCPF.data?.fotoCertiface,
                `fotoCertiface ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(
                `data:image/png;base64, ${RequestUserDocumentsCPF.data?.fotoCertiface}`,
              ),
          },
        ],
      },
      {
        hidden: !form.temCartaoCredito,
        title: 'Documentos da proposta',
        data: [
          {
            disabled: {
              download: false,
              view: false,
            },
            nome: 'Contrato cartão de crédito',
            onClickDownload: () => onClickDownloadContract(),
            onClickView: () => window.open(urlContract),
          },
          {
            disabled: {
              download: !cardDeliveryTerm.term?.front,
              view: !cardDeliveryTerm.term?.front,
              upload: false,
            },
            nome: 'Termo de entrega do cartão',
            onClickDownload: () =>
              downloadBase64Image(
                cardDeliveryTerm.term?.front.replace(
                  'data:image/png;base64, ',
                  '',
                ),
                `termoEntregaCartao ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(cardDeliveryTerm.term?.front),
            onClickUpload: () => triggerFileCardDeliveryTermFrontPicker(),
          },
          {
            disabled: {
              download: true,
              view: false,
            },
            nome: 'Termo de Uso',
            onClickDownload: () => null,
            onClickView: () =>
              window.open(process.env.REACT_APP_MS_TERMOS_CONDICOES),
          },
          {
            disabled: {
              download: true,
              view: false,
            },
            nome: 'Politica de privacidade',
            onClickDownload: () => null,
            onClickView: () =>
              window.open(process.env.REACT_APP_MS_TERMOS_POLITICA_PRIVACIDADE),
          },
          {
            disabled: {
              download: false,
              view: false,
            },
            nome: 'Comprovante limite de crédito',
            onClickDownload: () =>
              downloadBase64Image(
                RequestUserDocuments.data?.documentoLimiteCredito,
                `comprovanteLimiteCredito ${form.nome.value}`,
              ),
            onClickView: () =>
              handleOpenImageInNewTab(
                `data:image/png;base64, ${RequestUserDocuments.data?.documentoLimiteCredito}`,
              ),
          },
        ],
      },
      {
        hidden: !form.temCartaoCredito,
        title: 'Documento dos benefícios',
        data: (() => {
          if (cardInfos?.data?.[0]?.categoria === 'Platinum') {
            return [...benefitsDocuments, cardInfos];
          }
          return benefitsDocuments;
        })(),
      },
      {
        hidden: !form.temCartaoCredito,
        title: 'Documento dos benefícios da central de eventos',
        data:
          benefitsDocuments?.length === 0
            ? []
            : benefitsDocuments
                ?.filter(
                  (e) =>
                    e?.contratoAtual?.origemAdesao === 'CENTRAL_EVENTOS' ||
                    e?.contratoAtual?.linkGravacaoVenda,
                )
                .flatMap((e) => {
                  const isCanceled =
                    e?.contratoAtual?.statusBeneficio === 'CANCELADO';

                  const arr = [];

                  arr.push({
                    disabled: {
                      download: false,
                      view: false,
                    },
                    nome: `Contrato ${e.contratoAtual.nome}`, // Nome do contrato atual
                    statusBeneficio: e.contratoAtual.statusBeneficio, // Status do benefício
                    onClickDownload: () =>
                      onClickDownloadWavFile(
                        e.contratoAtual?.linkGravacaoVenda,
                      ), // Função para baixar o arquivo WAV
                    onClickView: isCanceled
                      ? () =>
                          handleOpenModalCanceledBenefit(
                            e.contratoAtual.linkContrato,
                            e.contratoAtual.cancelamentoContratoPadrao,
                          )
                      : e.contratoAtual.cancelamentoContratoPadrao
                      ? () =>
                          window.open(
                            e.contratoAtual.cancelamentoContratoPadrao,
                          )
                      : () => window.open(e.contratoAtual.linkContrato),
                  });

                  return arr;
                }),
      },
      {
        title: 'Documentos dos empréstimos ',
        data:
          contractedWithdraw?.length === 0
            ? []
            : contractedWithdraw.flatMap((e) => ({
                disabled: {
                  download: e.linkContrato,
                  view: false,
                },
                nome: `TeuCard Saque a vista em ${moment(
                  e.dataPagamento,
                  'YYYY-MM-DD HH:mm',
                ).format('DD/MM/YYYY [as] HH:mm')}`,
                onClickDownload: () => onClickDownloadContract(),
                onClickView: () => window.open(e.linkContrato),
              })),
      },
      {
        title: 'Documentos gerais',
        handleClickUpload: () => setModalUploadDocumentOpen(true),
        data: [
          ...(proposalDocuments?.length === 0
            ? []
            : proposalDocuments?.flatMap((e) => ({
                disabled: {
                  download: true,
                  view: false,
                  delete: !userRoles.includes('ADM'),
                },
                onClickDelete: !userRoles.includes('ADM')
                  ? () => {
                      setDialogIsOpen(true);
                      setDialogText(
                        'Apenas usuários com a permissão de ADM podem excluir um arquivo!',
                      );
                    }
                  : () => {
                      setSelectedDocumentFile(e);
                      setDialogDeleteDocumentOpen(true);
                    },
                nome: e.nome,
                onClickView: () => {
                  setSelectedDocumentFile(e);

                  handleDownloadDocument(e);
                },
              }))),
          ...(diverseDocuments.length === 0
            ? []
            : diverseDocuments.map((e) => ({
                disabled: {
                  download: true,
                  view: false,
                  delete: true,
                },
                onClickDelete: !userRoles.includes('ADM')
                  ? () => {
                      setDialogIsOpen(true);
                      setDialogText(
                        'Apenas usuários com a permissão de ADM podem excluir um arquivo!',
                      );
                    }
                  : () => {
                      setSelectedDocumentFile(e);
                      setDialogDeleteDocumentOpen(true);
                    },
                nome: e.nome,
                onClickView: () =>
                  handleOpenImageInNewTab(`data:image/png;base64, ${e.file}`),
              }))),
        ],
      },
    ],
    [
      form,
      RequestUserDocuments,
      documentsImage,
      benefits,
      form,
      proposalDocuments,
    ],
  );
  const documentsCollapseHeight = useMemo(
    () => dataDocuments.map((e) => e?.data?.flat().flat()).flat().length * 10,
    [dataDocuments],
  );

  return (
    <>
      <BenefitModal
        show={openBenefitsModal}
        benefits={benefitsDocuments}
        platinum={cardInfos}
        closeModal={(e) => setOpenBenefitsModal(false)}
        authorizingBenefitId={selectedOption}
        downloadPlatinum={onClickDownloadPlatinumContract}
        viewPlatinum={onClickViewPlatinumContract}
      />
      <Loading
        status={
          api.loading ||
          apiUserUpdate.loading ||
          apiBlockCardReasons.loading ||
          apiBlockCard.loading ||
          RequestInvoiceExpiration.loading ||
          POSTReferenceAPI.loading ||
          POSTDuplicateCard.loading ||
          consultLimitHistory.loading ||
          apiCancelClient.loading ||
          RequestSearchHistoricalBenefitProposal.loading ||
          GetContractedBenefits.loading ||
          GETDocumentTypes.loading ||
          GETProposalDocuments.loading ||
          POSTProposalDocuments.loading ||
          DELETEProposalDocuments.loading ||
          POSTGetDocumentBase64.loading ||
          consultDaysDelay.loading ||
          AuthValidatePassword.loading ||
          putAutomaticLimit.loading ||
          GetInformationKYCClient.loading ||
          DeleteRestritionKYC.loading ||
          RequestUserDiverseDocuments.loading
        }
      />
      <St.InputFileHidden
        ref={inputCardDeliveryTerm}
        type="file"
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        onChange={(e) => fileSelected(e, 'term', 'front')}
      />
      <St.InputFileHidden
        ref={inputUploadDocument}
        type="file"
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        onChange={(e) => setSelectedDocumentFile(e.target.files[0])}
      />

      <Dialog
        icon={icons.warning}
        open={dialogSizeFileMessage}
        positiveLabel="Entendi"
        positiveAction={() => setDialogSizeFileMessage(false)}
        message={`O tamanho do arquivo enviado é de ${fileSize(
          sizeFile,
        )}, o tamanho máximo permitido é de 1 MB.`}
      />
      <Dialog
        icon={icons.warning}
        open={openDialogKYC}
        title={
          <span style={{ color: '#E4003A', fontWeight: 'bold' }}>
            Liberar KYC
          </span>
        }
        message="Deseja LIBERAR o cliente para uma nova consulta ao KYC?"
        positiveLabel="SIM"
        negativeLabel="NÃO"
        positiveAction={removeRestritionKYC}
        negativeAction={() => setOpenDialogKYC(false)}
        buttonDisabledPositive={senha?.length !== 6}
      >
        <div style={{ marginTop: 20 }}>
          <Input
            name="senha"
            id="senha"
            type="password"
            label="Digite sua senha para confirmar:"
            maxLength={6}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
        </div>
      </Dialog>
      <Dialog
        widthInPercent={Object.keys(dataKYC).length <= 0 ? 0 : 85}
        icon={icons.exclamation}
        open={dialogHistoryConsultKYC}
        title={<span style={{ color: '#e71037' }}>Detalhes resposta KYC</span>}
        negativeLabel="Voltar"
        positiveLabel="Liberar"
        negativeAction={() => setDialogHistoryConsultKYC(false)}
        positiveAction={() => {
          setDialogHistoryConsultKYC(false);
          setOpenDialogKYC(true);
        }}
        buttonDisabledPositive={
          !showOptionsRule() || Object.keys(dataKYC).length <= 0
        }
        buttonsLeft
      >
        {Object.keys(dataKYC).length <= 0 ? (
          <span>O cliente está liberado e não possui rejeição!</span>
        ) : (
          <Grid xs={1} sm={2} lg={2} gap={30}>
            <ContainerFlex marginLeft={20}>
              <Input
                readOnly
                label="Nome"
                value={dataKYC?.detalhamento?.nome}
              />
              <Input
                readOnly
                label="Data Nascimento"
                value={moment(
                  dataKYC?.detalhamento?.dataNascimento,
                  'YYYY-MM-DD',
                ).format('DD/MM/YYYY')}
              />
              <Input
                readOnly
                label="Nome da Mãe"
                value={dataKYC?.detalhamento?.nomeMae}
              />
              <Input
                readOnly
                label="Status CPF receita federal"
                value={dataKYC?.detalhamento?.statusCpf}
              />
              <Input
                readOnly
                label="País do CPF"
                value={dataKYC?.detalhamento?.paisCpf}
              />
              <Input
                readOnly
                label="Ano da indicação de óbito"
                value={dataKYC?.detalhamento?.indicacaoObito}
              />
              <Input
                readOnly
                label="Pessoa politicamente exposta?"
                value={
                  dataKYC?.detalhamento?.politicamenteExposto ? 'SIM' : 'NÃO'
                }
              />
              <Input
                readOnly
                label="Mensagem do provedor"
                value={dataKYC?.detalhamento?.mensagemRetornada}
              />
            </ContainerFlex>
            <ContainerFlex marginRight={20}>
              <Input readOnly label="CPF" value={dataKYC?.cpf} />
              <Input
                readOnly
                label="Gênero"
                value={dataKYC?.detalhamento?.genero}
              />
              <Input
                readOnly
                label="Nome do Pai"
                value={dataKYC.detalhamento?.nomePai}
              />
              <Input
                readOnly
                label="Nacionalidade"
                value={dataKYC.detalhamento?.nacionalidade}
              />
              <Input
                readOnly
                label="Indicação de óbito"
                value={dataKYC.detalhamento.indicacaoObito}
              />
              <Input
                readOnly
                label="Está sancionado?"
                value={dataKYC?.detalhamento?.sancionado ? 'SIM' : 'NÃO'}
              />
              <Input readOnly label="Status" value={dataKYC?.status} />
              <Input
                readOnly
                label="Data consulta"
                value={moment(dataKYC.dataUltimaConsulta, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY',
                )}
              />
              <Input
                readOnly
                label="Restrito até"
                value={moment(dataKYC.dataRestricao, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY',
                )}
              />
            </ContainerFlex>
          </Grid>
        )}
      </Dialog>

      <Dialog
        icon={icons.exclamation}
        open={dialogDuplicateCard}
        negativeLabel="Voltar"
        negativeAction={() => setDialogDuplicateCard(false)}
        buttonDisabledPositive={tax === null || cardDesign === ''}
        positiveLabel="Solicitar"
        positiveAction={() => {
          if (tax !== null && cardDesign !== '') {
            setModalPasswordOpen({ open: true, type: 'twoWayCard' });
          } else if (cardDesign === '') {
            setErrorCardDesign('Selecione uma opção!');
          } else setErrorTax('Selecione uma opção!');
          setErrorTax('');
          setErrorCardDesign('');
        }}
        title={
          <span style={{ color: 'red' }}>Solicitação de 2ª via de Cartão</span>
        }
      >
        <strong>Design do cartão:</strong>

        <br />
        <Grid xs={1} sm={2} gap={20}>
          {cardInfos.data?.map((el) => (
            <ContainerFlex row center>
              <RadioButton
                id={el.idExterno}
                name={el.modeloTema}
                checked={
                  cardDesign === el.modeloTema || el.modeloTema === 'PLATINUM'
                }
                onClick={() => setCardDesign(el.modeloTema)}
              />
              <St.IconCardDesign src={el.urlImagem} />
              <strong style={{ color: 'gray' }}>{el.modeloTema}</strong>
            </ContainerFlex>
          ))}
        </Grid>

        <br />
        <St.ErrorMessage>{errorCardDesign}</St.ErrorMessage>
        <br />

        <strong>Cobrar tarifa ?</strong>
        <br />
        <ContainerFlex row horizontal align="center" marginBottom={5}>
          <RadioButton checked={tax === true} onClick={() => setTax(true)} />
          Sim
        </ContainerFlex>
        <ContainerFlex row horizontal align="center" marginBottom={5}>
          <RadioButton checked={tax === false} onClick={() => setTax(false)} />
          Não
        </ContainerFlex>
        <St.ErrorMessage>{errorTax}</St.ErrorMessage>
      </Dialog>

      <Dialog
        icon={icons.exclamation}
        open={modalUploadDocumentOpen}
        negativeLabel="Cancelar"
        negativeAction={() => setModalUploadDocumentOpen(false)}
        positiveLabel="Fazer upload"
        positiveAction={() => handleUploadFile()}
        message="Upload de documento"
        buttonDisabledPositive={!selectedDocumentFile || !form.tipoDoc}
      >
        <ContainerFlex row padding={12}>
          <ContainerFlex marginRight={10}>
            <Select
              label="Tipo de documento"
              value={form.tipoDoc}
              options={GETDocumentTypes.data?.map((e) => ({
                id: e.id,
                value: e.nome,
              }))}
              onChange={(e) => setForm({ ...form, tipoDoc: e })}
            />
          </ContainerFlex>
          <ContainerFlex marginRight={10}>
            <Button
              size="medium"
              onClick={() => inputUploadDocument.current.click()}
            >
              Selecionar Documento
            </Button>
          </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex row padding={12}>
          {selectedDocumentFile && <span>{selectedDocumentFile?.name}</span>}
        </ContainerFlex>
      </Dialog>

      <Dialog
        icon={icons.exclamation}
        open={dialogCancelCardShow}
        negativeLabel="Não"
        negativeAction={() => setDialogCancelCardShow(false)}
        positiveLabel="Sim"
        positiveAction={() =>
          setModalPasswordOpen({ open: true, type: 'cancelClient' })
        }
        message="Você tem certeza que deseja cancelar o cliente?"
      >
        {modalPasswordOpen.type === 'cancelClient' ? (
          <PasswordModal
            onClose={() => setModalPasswordOpen({ open: false, type: '' })}
            onSuccessValidation={() => cancel()}
          />
        ) : null}
        <Select
          label="Motivo do cancelamento"
          value={form.tipoCanc}
          options={[
            { id: 'CANCELAMENTO_SOLICITACAO', value: 'Canc. Solic. Cliente' },
          ]}
          onChange={(e) => setForm({ ...form, tipoCanc: e })}
        />
      </Dialog>

      {modalPasswordOpen.type === 'twoWayCard' ? (
        <PasswordModal
          onClose={() => setModalPasswordOpen({ open: false, type: '' })}
          onSuccessValidation={() => handleClickDuplicateCard()}
        />
      ) : null}

      <Dialog
        icon={icons.exclamation}
        open={dialogAskBlockCardShow}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogAskBlockCardShow(false)}
        positiveLabel="Bloquear"
        positiveAction={() =>
          setModalPasswordOpen({ open: true, type: 'blockCard' })
        }
        message="Você tem certeza que deseja bloquear o cartão?"
      >
        {modalPasswordOpen.type === 'blockCard' ? (
          <PasswordModal
            onClose={() => setModalPasswordOpen({ open: false, type: '' })}
            onSuccessValidation={() => block()}
          />
        ) : null}
        <Select
          label="Motivo do bloqueio"
          value={reasonForBlocking}
          options={optionsForBlocking}
          onChange={(id) => setReasonForBlocking(id)}
          errorMessage={errorReasonBlock}
        />
      </Dialog>

      <Dialog
        icon={icons.exclamation}
        open={dialogDeleteDocumentOpen}
        positiveLabel="Sim"
        negativeAction={() => setDialogDeleteDocumentOpen(false)}
        negativeLabel="Não"
        positiveAction={() => handleDeleteFile()}
        message={`Deseja excluir o documento ${selectedDocumentFile?.nome}`}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogErrorCancelCard}
        positiveLabel="Entendi"
        positiveAction={() => setDialogErrorCancelCard(false)}
        message={dialogText}
      />

      <Dialog
        widthInPercent={85}
        icon={icons.exclamation}
        open={dialogHistory}
        title={<span style={{ color: 'red' }}> Histórico de Cartões </span>}
        positiveLabel="Voltar"
        positiveAction={() => setDialogHistory(false)}
      >
        <div style={{ height: 450, overflow: 'scroll' }}>
          <St.TableHistory>
            <thead>
              <tr>
                <th>Número do Cartão</th>
                <th>Status</th>
                <th>Data emissão</th>
                <th>Data ocorrência</th>
                <th style={{ borderRight: '1px solid grey' }}>Descrição</th>
                <th style={{ borderRight: '1px solid grey' }}>Motivo</th>
                {/* <th style={{ borderRight: '1px solid grey' }}>Ação</th> */}
              </tr>
            </thead>
            <tbody>
              {RequestHistoricCard.data?.map((cartao) => (
                <tr>
                  <td>{cartao.cartao}</td>
                  <td>{cartao.status}</td>
                  <td>{moment(cartao.dataEmissao).format('DD/MM/YYYY')}</td>
                  <td>{moment(cartao.dataHistorico).format('DD/MM/YYYY')}</td>
                  <td style={{ borderRight: '1px solid grey' }}>
                    {cartao.operacao}
                  </td>
                  <td style={{ borderRight: '1px solid grey' }}>
                    {cartao.motivo}
                  </td>
                </tr>
              ))}
            </tbody>
          </St.TableHistory>
        </div>
      </Dialog>

      <Dialog
        icon={icons.exclamation}
        open={dialogLimitHistory}
        title={
          <span style={{ color: 'red' }}>
            {' '}
            Histórico de Limites de compras{' '}
          </span>
        }
        positiveLabel="Voltar"
        positiveAction={() => setDialogLimitHistory(false)}
      >
        <St.TableHistory>
          <tr>
            <th>Limite anterior</th>
            <th>Novo limite</th>
            <th>Data da alteração</th>
            <th style={{ borderRight: '1px solid grey' }}>Tipo de alteração</th>
          </tr>

          {limitHistoryData.map((item) => (
            <tr key={item.celular}>
              <td>{`R$ ${Masks.currencyMask(item.limiteAnterior)}`}</td>
              <td>{`R$ ${Masks.currencyMask(item.valorEstabelecido)}`}</td>
              <td>{moment(item.dataAumentoLimite).format('DD/MM/YYYY')}</td>
              <td style={{ borderRight: '1px solid grey' }}>
                {item.tipoAumento}
              </td>
            </tr>
          ))}
        </St.TableHistory>
        {totalPagesLimitHistory > 1 && (
          <ContainerFlex row horizontal center marginTop={20}>
            <Pagination
              totalPages={totalPagesLimitHistory}
              onCurrentPageChange={(e) => getLimitHistory(e)}
            />
          </ContainerFlex>
        )}
      </Dialog>
      <Dialog
        icon={icons.exclamation}
        open={dialogLimitHistoryWithdraw}
        title={
          <span style={{ color: 'red' }}> Histórico de Limites de saque </span>
        }
        positiveLabel="Voltar"
        positiveAction={() => setDialogLimitHistoryWithdraw(false)}
      >
        <St.TableHistory>
          <tr>
            <th>Limite anterior</th>
            <th>Novo limite</th>
            <th>Data da alteração</th>
            <th style={{ borderRight: '1px solid grey' }}>Tipo de alteração</th>
          </tr>

          {getWithDrawLimitHistory?.data?.map((item) => (
            <tr key={item.dataAcao}>
              <td>{`R$ ${Masks.currencyMask(
                item.limiteSaqueConcedidoAnterior,
              )}`}</td>
              <td>{`R$ ${Masks.currencyMask(
                item.limiteSaqueConcedidoAtual,
              )}`}</td>
              <td>{moment(item.dataAcao).format('DD/MM/YYYY')}</td>
              <td style={{ borderRight: '1px solid grey' }}>
                {item.tipoAumento}
              </td>
            </tr>
          ))}
        </St.TableHistory>
        {/* {totalPagesLimitHistory > 1 && (
          <ContainerFlex row horizontal center marginTop={20}>
            <Pagination
              totalPages={totalPagesLimitHistory}
              onCurrentPageChange={(e) => getLimitHistory(e)}
            />
          </ContainerFlex>
        )} */}
      </Dialog>
      <Dialog
        icon={icons.exclamation}
        open={dialogBlackListCards}
        title={
          <span style={{ color: 'red', padding: 10 }}>
            {' '}
            Selecione o cartão que deseja remover da Blacklist{' '}
          </span>
        }
        positiveLabel="Remover"
        positiveAction={() => {
          removeBlacklist();
        }}
        negativeAction={() => {
          setDialogBlackListCards(false);
        }}
        negativeLabel="Voltar"
      >
        <St.BlackListCards>
          {form?.carteira?.cartoes?.map((item) => {
            if (item.blackList) {
              return (
                <div className="card">
                  <RadioButton
                    id={item.numeroCartao}
                    name={item.numeroCartao}
                    checked={cardNumber === item.id}
                    onClick={() => setCardNumber(item.id)}
                  />
                  <div className="card-number">{item.numeroCartao}</div>
                </div>
              );
            }
            return null;
          })}
        </St.BlackListCards>
      </Dialog>
      <Dialog
        icon={icons.exclamation}
        open={dialogWalletCards}
        title={<span style={{ color: 'red' }}> Cartões cadastrados </span>}
        positiveLabel="Voltar"
        positiveAction={() => setDialogWalletCards(false)}
      >
        <St.TableHistory>
          <tr>
            <th>Bandeira</th>
            <th>Número do cartão</th>
            <th>Principal</th>
            <th>Validado</th>
            <th>BlackList</th>
          </tr>

          {form?.carteira?.cartoes?.map((item) => (
            <tr key={item.numeroCartao}>
              <td>{item.bandeira}</td>
              <td>{item.numeroCartao}</td>
              <td>{item.principal ? 'SIM' : 'NÃO'}</td>
              <td>{item.validado ? 'SIM' : 'NÃO'}</td>
              <td>{item.blackList ? 'SIM' : 'NÃO'}</td>
            </tr>
          ))}
        </St.TableHistory>
      </Dialog>

      <Dialog
        icon={icons.confirmation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={dialogText}
      />

      <Dialog
        icon={icons.warning}
        open={dialogIsOpenError}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpenError(false)}
        message={dialogText}
      />

      <Dialog
        icon={icons.warning}
        open={dialogBlackListRemoveError}
        positiveLabel="Entendi"
        positiveAction={() => goBack()}
        message={dialogText}
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogCancelCardIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => goBack()}
        message={dialogText}
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogDocumentOpen}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogDocumentOpen(false);
          handleGetOtherDocuments();
        }}
        message={dialogDocumentMessage}
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogMessageStatus}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogMessageStatus(false);
        }}
        message={dialogMessage}
      />
      <Dialog
        icon={icons.warning}
        open={cashBackPopUp}
        positiveLabel="Entendi"
        positiveAction={() => {
          setCashBackPopUp(false);
        }}
      >
        <div style={{ width: 400, height: 150 }}>
          <St.TableHistory>
            <thead>
              <tr>
                <th>Celular vinculado</th>
                <th>Saldo cashback</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{Masks.phoneMask(Masks.phoneUnmask(zeussPhone)).masked}</td>
                <td>{`R$ ${Masks.currencyMask(zeussBalance)}`}</td>
              </tr>
            </tbody>
          </St.TableHistory>
        </div>
        <span
          style={{ color: THEMES.colors.error, marginTop: 30, marginLeft: 60 }}
        >
          {balanceError}
        </span>
      </Dialog>

      <Dialog
        icon={icons.confirmation}
        open={dialogIsOpenSaveR}
        message="Deseja salvar as alterações, das suas referências?"
        negativeLabel="Cancelar"
        negativeAction={() => setDialogIsOpenSaveR(false)}
        positiveLabel="Continuar"
        positiveAction={addReferenceAPI}
      />

      <Dialog
        icon={icons.warning}
        open={dialogUpdateLimit}
        message={`Deseja ${
          automaticLimit ? 'DESATIVAR' : 'ATIVAR'
        } o aumento de limite automático para o cliente?`}
        positiveLabel="Sim"
        negativeLabel="Não"
        positiveAction={handleUpdateAutomaticLimit}
        negativeAction={() => setDialogUpdateLimit(false)}
        buttonDisabledPositive={senha?.length !== 6}
      >
        <div style={{ marginTop: 20 }}>
          <Input
            name="senha"
            id="senha"
            type="password"
            label="Digite sua senha para confirmar"
            maxLength={6}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
        </div>
      </Dialog>

      <Dialog
        open={openDialog}
        message={popUpMessage}
        positiveAction={() => {
          setOpenDialog(false);
          handleCustomerBase();
        }}
        positiveLabel="Entendi"
        icon={icons.check}
      />
      <Dialog
        open={popUpError}
        message={popUpErrorMessage}
        positiveAction={() => {
          setPopUpError(false);
        }}
        positiveLabel="Entendi"
        icon={icons.warning}
      />
      <Dialog
        open={modalCanceledBenefit}
        title="Deseja visualizar qual termo?"
        negativeAction={() => {
          setModalCanceledBenefit(false);
        }}
        negativeLabel="Cancelar"
        icon={icons.warning}
      >
        <ContainerFlex marginTop={20} row gap="20">
          <Button
            onClick={() => window.open(benefitContractLinks.linkContrato)}
          >
            Termo de adesão
          </Button>
          <Button
            onClick={() =>
              window.open(benefitContractLinks.linkContratoCancelado)
            }
          >
            Termo de cancelamento
          </Button>
        </ContainerFlex>
      </Dialog>

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <Collapse
              collapseHeight={120}
              title="1. Cliente"
              initialState="open"
            >
              <ContainerFlex marginLeft={26} marginRight={26}>
                <Grid xs={1} lg={2} gap={30}>
                  <ContainerFlex marginTop={10}>
                    <ContainerFlex center marginBottom={30}>
                      <PictureCard
                        width={200}
                        height={200}
                        showOptions
                        src={
                          documents?.fotoCertiface
                            ? `data:image/jpeg;base64, ${documents?.fotoCertiface}`
                            : icons.userAvatar
                        }
                      />
                    </ContainerFlex>

                    <Grid xs={2} lg={2} gap={20}>
                      <Input
                        label="Status do Cliente"
                        readOnly
                        value={form?.statusCliente.value}
                      />
                      <Input
                        label="Readmitido"
                        readOnly
                        value={form?.readmissao ? 'SIM' : 'NÃO'}
                      />
                    </Grid>
                    <Input
                      label="CPF"
                      value={Masks.cpfMask(location.state?.cpf).masked}
                      placeholder="000.000.000-00"
                      maxLength={14}
                      mask={MaskTypes.CPF}
                    />
                    <Input
                      label="Nome completo"
                      value={form.nome.value}
                      name={form.nome.name}
                    />
                    <Input
                      label="Nome social"
                      name={form?.nomeSocial?.name}
                      value={form?.nomeSocial?.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                      errorMessage={errors.socialName}
                      edit
                    />
                    <Input
                      label="Telefone"
                      name={form.celular.name}
                      value={form.celular.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                      placeholder="(00) 00000-0000"
                      maxLength={15}
                      errorMessage={errors.phone}
                      mask={MaskTypes.PHONE}
                      edit
                    />
                    <Input
                      label="Segundo Telefone"
                      name={form.celular2.name}
                      value={form.celular2.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                      placeholder="(00) 00000-0000"
                      errorMessage={errors.phone2}
                      maxLength={15}
                      mask={MaskTypes.PHONE}
                      edit
                    />
                    <Input
                      label="Data de nascimento"
                      name={form.dataNascimento.name}
                      value={form.dataNascimento.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      mask={MaskTypes.DATE}
                      edit
                    />
                    {form.cardStatus.value === 'CANCELAMENTO_EXPERIENCIA' ||
                    form.cardStatus.value === 'CANCELAMENTO_SOLICITACAO' ||
                    form.cardStatus.value === 'CANCELAMENTO_INATIVIDADE' ? (
                      <Input
                        label="Data de cancelamento"
                        name={form?.dataCancelamento.name}
                        value={form?.dataCancelamento.value}
                        onChange={(e) => setFormState(e, form, setForm)}
                        placeholder="DD/MM/YYYY"
                        maxLength={10}
                        mask={MaskTypes.DATE}
                      />
                    ) : null}
                  </ContainerFlex>

                  <ContainerFlex>
                    <Input
                      label="E-mail"
                      value={form.email.value?.trim()}
                      name={form.email.name}
                      onChange={(e) => setFormState(e, form, setForm)}
                      errorMessage={errors.email}
                      edit
                    />
                    <Input label="Empresa" value={form.lojaNome.value} edit />

                    <Select
                      label="Sexo"
                      name={form.genero.name}
                      value={form.genero.value}
                      options={[
                        { id: 'M', value: 'Masculino' },
                        { id: 'F', value: 'Feminino' },
                      ]}
                      onChange={(e) => {
                        setFormState(
                          { target: { name: form.genero.name, value: e } },
                          form,
                          setForm,
                        );
                      }}
                    />
                    <Input
                      label="CEP"
                      name={form.endCep.name}
                      value={form.endCep.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                      placeholder="00000-000"
                      maxLength={9}
                      mask={MaskTypes.CEP}
                      edit
                    />
                    <Input
                      label="Endereço"
                      name={form.endLogradouro.name}
                      value={form.endLogradouro.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                      edit
                    />

                    <div>
                      <Grid xs={1} sm={2} gap={10}>
                        <ContainerFlex>
                          <Input
                            label="Número"
                            name={form.endNumero.name}
                            value={form.endNumero.value}
                            onChange={(e) => setFormState(e, form, setForm)}
                            edit
                          />
                        </ContainerFlex>

                        <ContainerFlex>
                          <Input
                            label="Complemento"
                            name={form.endComplemento.name}
                            value={form.endComplemento.value}
                            onChange={(e) => setFormState(e, form, setForm)}
                            edit
                          />
                        </ContainerFlex>
                      </Grid>

                      <Grid xs={1} sm={3} gap={10}>
                        <ContainerFlex>
                          <Input
                            label="Bairro"
                            name={form.endBairro.name}
                            value={form.endBairro.value}
                            onChange={(e) => setFormState(e, form, setForm)}
                            edit
                          />
                        </ContainerFlex>

                        <ContainerFlex>
                          <Input
                            label="Cidade"
                            name={form.endCidade.name}
                            value={form.endCidade.value}
                            onChange={(e) => setFormState(e, form, setForm)}
                            edit
                          />
                        </ContainerFlex>

                        <ContainerFlex>
                          <Input
                            label="UF/Estado"
                            name={form.endUf.name}
                            value={form.endUf.value}
                            onChange={(e) => setFormState(e, form, setForm)}
                            edit
                          />
                        </ContainerFlex>
                      </Grid>
                    </div>

                    <Input
                      label="ID do cliente"
                      value={form.idClienteAutorizador.value}
                    />

                    <div style={{ marginBottom: '10px', marginTop: '15px' }}>
                      <Checkbox
                        value={form.aposentadoPensionista}
                        readonly
                        message="Aposentado ou Pensionista."
                      />
                    </div>

                    <Input
                      label="Profissão"
                      edit
                      name={form.profissao.name}
                      value={form.profissao.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                    />

                    <Input
                      label="Nome da Empresa/Órgão onde trabalha"
                      edit
                      name={form.nomeEmpresa.name}
                      value={form.nomeEmpresa.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                    />

                    <Input
                      label="Renda estimada"
                      name={form.rendaInformada.name}
                      value={form.rendaInformada.value}
                      onChange={(e) => setFormState(e, form, setForm)}
                      edit
                      mask={MaskTypes.CURRENCY}
                    />
                  </ContainerFlex>
                </Grid>
              </ContainerFlex>

              <CardView marginBottom={40} marginTop={20}>
                <ContainerFlex row end padding={24}>
                  <Button
                    loading={isLoading}
                    marginLeft={16}
                    onClick={updateClient}
                    fitContent
                  >
                    Salvar
                  </Button>
                </ContainerFlex>
              </CardView>
            </Collapse>

            <Collapse
              initialState="close"
              collapseHeight={documentsCollapseHeight}
              title="2. Documentos"
              actionOnOpen={handleLoadClientDocuments}
            >
              <ContainerFlex marginLeft={26} marginRight={26}>
                <DocumentsTable
                  data={dataDocuments}
                  dataBenefit={dataDocuments[3].data}
                  handleSelectOption={(e) => setSelectedOption(e)}
                  handleOpenDetails={(e) => setOpenBenefitsModal(e)}
                />
              </ContainerFlex>
            </Collapse>
            {form.temCarteiraDigital && (
              <Collapse collapseHeight={40} title="3. Carteira digital">
                <ContainerFlex marginLeft={26} marginRight={26}>
                  <Grid xs={1} lg={2} gap={30}>
                    <Input
                      label="Data adesão"
                      value={
                        form?.carteira?.dataAdesao?.length
                          ? moment(form?.carteira?.dataAdesao).format(
                              'DD/MM/YYYY',
                            )
                          : ''
                      }
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      mask={MaskTypes.DATE}
                      readOnly
                    />
                    <ContainerFlex
                      style={{ maxHeight: '52px' }}
                      row
                      align="center"
                    >
                      <Input
                        label="Quantidade de cartões cadastrados"
                        value={form?.carteira?.cartoes?.length}
                        readOnly
                      />
                      <Button
                        style={{ width: 280, height: 37, marginLeft: 10 }}
                        onClick={() => setDialogWalletCards(true)}
                        disabled={!form?.carteira?.cartoes?.length}
                      >
                        Ver cartões
                      </Button>
                    </ContainerFlex>
                    <Input
                      label="Data primeira compra"
                      value={
                        form?.carteira?.dataPrimeiraCompra?.length
                          ? moment(form?.carteira?.dataPrimeiraCompra).format(
                              'DD/MM/YYYY',
                            )
                          : ''
                      }
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      mask={MaskTypes.DATE}
                      readOnly
                    />
                    <Input
                      label="Operador de Venda"
                      value={
                        form.carteira.nomeOperador
                          ? form.carteira.nomeOperador
                          : ''
                      }
                      readOnly
                    />
                  </Grid>
                  <ContainerFlex align="flex-end">
                    <Button
                      style={{ width: 280, height: 37, marginTop: 20 }}
                      onClick={() => setDialogBlackListCards(true)}
                      disabled={
                        !form?.carteira?.cartoes?.some((item) => item.blackList)
                      }
                    >
                      Remover blackList
                    </Button>
                  </ContainerFlex>
                </ContainerFlex>
              </Collapse>
            )}

            {form.temCartaoCredito && (
              <>
                <Collapse
                  actionOnOpen={() => openCardDropDownAction()}
                  collapseHeight={55}
                  title={form.temCarteiraDigital ? '4. Cartão' : '3. Cartão'}
                >
                  <ContainerFlex marginLeft={26} marginRight={26}>
                    <Grid xs={1} lg={2} gap={30}>
                      <ContainerFlex marginBottom={10}>
                        <Input
                          label="Número do cartão"
                          value={
                            Masks.creditCardMask(form.cardNumeroCartao.value)
                              .masked
                          }
                          placeholder="0000.0000.0000.0000"
                          maxLength={19}
                          mask={MaskTypes.CREDIT_CARD}
                          readOnly
                        />

                        <Input
                          label="Status do cartão"
                          value={form.cardStatus.value}
                          readOnly
                        />

                        <Input
                          label="Data de Impressão"
                          value={
                            form.dataImpressao.value
                              ? moment(form.dataImpressao.value).format(
                                  'DD/MM/YYYY',
                                )
                              : ''
                          }
                          placeholder="DD/MM/YYYY"
                          maxLength={10}
                          mask={MaskTypes.DATE}
                          readOnly
                        />

                        <Input
                          label="Dias em atraso"
                          value={daysDelay?.toString() || '0'}
                          readOnly
                        />
                        <Input
                          label="Limite disponível de compras"
                          name={form.cardLimiteCredito.name}
                          value={Masks.currencyMask(form.saldo.value)}
                          readOnly
                        />
                        <Input
                          label="Limite disponível de saque"
                          name={getTotalLimit?.data?.limiteDisponivelSaque}
                          value={Masks.currencyMask(
                            getTotalLimit?.data?.limiteDisponivelSaque,
                          )}
                          readOnly
                        />
                        <Input
                          label="Limite disponível total"
                          name={getTotalLimit?.data?.limiteDisponivelTotal}
                          value={Masks.currencyMask(
                            getTotalLimit?.data?.limiteDisponivelTotal,
                          )}
                          readOnly
                        />

                        <ContainerFlex marginTop={10}>
                          <span style={{ fontSize: '12px', color: '#757575' }}>
                            Optin
                          </span>
                          <ContainerFlex>
                            <Checkbox
                              value={form.optinTermoDeUso}
                              readonly
                              terms={
                                <>
                                  Li e concordo com os
                                  <St.ViewTerms
                                    onClick={() => handleTermsConditions()}
                                  >
                                    Termos e condições de uso
                                  </St.ViewTerms>
                                  e a
                                  <St.ViewTerms
                                    onClick={() => handleTermsPrivacy()}
                                  >
                                    Política de privacidade
                                  </St.ViewTerms>
                                </>
                              }
                            />
                          </ContainerFlex>
                          <ContainerFlex>
                            <Checkbox
                              value={form.checkWhatsapp}
                              readonly
                              terms="Desejo receber comunicação do meu interesse por WhatsApp."
                            />
                          </ContainerFlex>
                        </ContainerFlex>
                      </ContainerFlex>

                      <ContainerFlex>
                        <ContainerFlex
                          style={{ maxHeight: '52px' }}
                          row
                          align="center"
                        >
                          <ContainerFlex style={{ padding: '5px, 5px' }}>
                            <Select
                              label="Dia vencimento da fatura"
                              name={form.diaVencimento.name}
                              value={form.diaVencimento.value}
                              options={EXPIRATION_DAYS}
                              onChange={(val) => {
                                handleChange('diaVencimento', val);
                              }}
                              style={{ flex: 1 }}
                            />
                          </ContainerFlex>
                          <Button
                            style={{ width: 25, height: 37, marginLeft: 10 }}
                            disabled={
                              form.diaVencimento.value === previousDiaVencimento
                            }
                            onClick={handleUpdateInvoiceExpiration}
                          >
                            Salvar
                          </Button>
                        </ContainerFlex>
                        <ContainerFlex>
                          <Input
                            label="Melhor dia de compra"
                            value={form.melhorDiaCompra.value?.substring(0, 2)}
                            readOnly
                          />
                          <Input
                            label="Data de corte da fatura"
                            value={form.dataCorte.value}
                            placeholder="DD/MM/YYYY"
                            maxLength={10}
                            mask={MaskTypes.DATE}
                            readOnly
                          />
                        </ContainerFlex>
                        <ContainerFlex row align="center" style={{ gap: 40 }}>
                          <Input
                            label="Categoria"
                            value={
                              form?.categoriaCartao.value?.toString() || '-'
                            }
                            readOnly
                          />
                          <Input
                            label="Tema do cartão"
                            value={form.temaCartao.value?.toString() || '-'}
                            readOnly
                          />
                          {!standard && (
                            <Button
                              style={{ width: 25, height: 37, marginLeft: 10 }}
                              onClick={() => {
                                getZeussBalance();
                                getZeussPhone();
                              }}
                              loading={
                                RequestConsultZeussBalance.loading &&
                                RequestConsultZeussPhone.loading
                              }
                            >
                              Zeuss
                            </Button>
                          )}
                        </ContainerFlex>
                        <ContainerFlex
                          style={{ maxHeight: '52px' }}
                          row
                          align="center"
                        >
                          <Input
                            label="Limite concedido de compras"
                            name={getTotalLimit?.data?.limiteConcedidoCompra}
                            value={Masks.currencyMask(
                              getTotalLimit?.data?.limiteConcedidoCompra,
                            )}
                            readOnly
                          />
                          <Button
                            style={{ width: 25, height: 37, marginLeft: 10 }}
                            onClick={() => setDialogLimitHistory(true)}
                            disabled={!limitHistoryData.length}
                          >
                            Histórico
                          </Button>
                        </ContainerFlex>
                        <ContainerFlex
                          style={{ maxHeight: '52px' }}
                          row
                          align="center"
                        >
                          <Input
                            label="Limite concedido de saque"
                            name={getTotalLimit?.data?.limiteConcedidoSaque}
                            value={Masks.currencyMask(
                              getTotalLimit?.data?.limiteConcedidoSaque,
                            )}
                            readOnly
                          />
                          <Button
                            style={{ width: 25, height: 37, marginLeft: 10 }}
                            onClick={() => setDialogLimitHistoryWithdraw(true)}
                            disabled={!getWithDrawLimitHistory?.data?.length}
                          >
                            Histórico
                          </Button>
                        </ContainerFlex>
                        <Input
                          label="Limite concedido total"
                          name={getTotalLimit?.data?.limiteConcedidoTotal}
                          value={Masks.currencyMask(
                            getTotalLimit?.data?.limiteConcedidoTotal,
                          )}
                          readOnly
                        />

                        <Grid xs={1} sm={2} lg={2} gap={20}>
                          <ContainerFlex>
                            <Input
                              label="Origem"
                              name={form.origemOptin.name}
                              value={
                                form.origemOptin.value
                                  ? form.origemOptin.value
                                  : '-'
                              }
                              readOnly
                            />
                          </ContainerFlex>
                          <ContainerFlex>
                            <Input
                              label="Data e Hora"
                              name={form.dataAtivacao.name}
                              value={
                                form.dataAtivacao.value
                                  ? moment(form.dataAtivacao.value).format(
                                      'DD/MM/YYYY HH:mm',
                                    )
                                  : '-'
                              }
                              readOnly
                            />
                          </ContainerFlex>
                        </Grid>
                      </ContainerFlex>
                    </Grid>
                    <ContainerFlex align="center" marginTop={30}>
                      <Grid xs={2} sm={3} lg={3} gap={15}>
                        <Button
                          onClick={() => goDetail()}
                          disabled={form.cardStatus.value !== 'COBRANCA'}
                        >
                          Reativar Cliente
                        </Button>
                        <Button
                          onClick={() => {
                            RequestHistoricCard.callApi();
                            setDialogHistory(true);
                          }}
                        >
                          Histórico
                        </Button>
                        <Button
                          onClick={() => {
                            const lastCard = api.data?.cartoes
                              ? api.data?.cartoes[api.data?.cartoes?.length - 1]
                                  .numeroCartao
                              : null;
                            apiBlockCardReasons.callApi();
                            getCardToken(lastCard);
                            setDialogAskBlockCardShow(true);
                          }}
                          disabled={
                            form.statusCliente.status ===
                              'CANCELAMENTO_EXPERIENCIA' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_SOLICITACAO' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_INATIVIDADE' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_PROPOSTA' ||
                            form.cardStatus.value === 'INADIMPLENTE' ||
                            form.cardStatus.value === 'COBRANCA'
                          }
                          loading={isLoading}
                        >
                          Bloquear
                        </Button>
                        <Button
                          onClick={() => setDialogCancelCardShow(true)}
                          disabled={
                            form.statusCliente.status ===
                              'CANCELAMENTO_EXPERIENCIA' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_SOLICITACAO' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_INATIVIDADE' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_PROPOSTA' ||
                            form.cardStatus.value === 'INADIMPLENTE' ||
                            form.cardStatus.value === 'COBRANCA'
                          }
                        >
                          Cancelar
                        </Button>
                        <Button
                          onClick={askOtherCard}
                          disabled={
                            form.statusCliente.status ===
                              'CANCELAMENTO_EXPERIENCIA' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_SOLICITACAO' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_INATIVIDADE' ||
                            form.statusCliente.status ===
                              'CANCELAMENTO_PROPOSTA' ||
                            form.cardStatus.value !== 'CANCELADO'
                          }
                        >
                          Solicitar 2ª via
                        </Button>
                        <Button onClick={() => setDialogUpdateLimit(true)}>
                          Limite Automático
                        </Button>
                      </Grid>
                    </ContainerFlex>
                  </ContainerFlex>
                </Collapse>

                <Collapse
                  collapseHeight={60}
                  title={
                    form.temCarteiraDigital ? '5. Empréstimo' : '4. Empréstimo'
                  }
                  initialState="close"
                  actionOnOpen={getInformationKYCClient}
                >
                  <ContainerFlex marginLeft={26} marginRight={26}>
                    <ContainerFlex marginTop={30}>
                      <ContainerFlex row end>
                        <Button
                          style={{ width: '229px' }}
                          marginLeft={16}
                          onClick={() => setDialogHistoryConsultKYC(true)}
                        >
                          KYC
                        </Button>
                        <Button
                          marginLeft={40}
                          onClick={goToHistoryLoanCelcoin}
                        >
                          Histórico Celcoin
                        </Button>
                      </ContainerFlex>
                    </ContainerFlex>
                  </ContainerFlex>
                </Collapse>

                <Collapse
                  collapseHeight={40}
                  title={
                    form.temCarteiraDigital
                      ? '6. Classificação'
                      : '5. Classificação'
                  }
                >
                  <ContainerFlex marginLeft={26} marginRight={26}>
                    <Grid xs={1} lg={2} gap={30}>
                      <Input
                        label="Descrição da classificação"
                        value={form.nivel}
                        readOnly
                      />
                      <Input
                        label="Data da classificação"
                        value={moment(form.dataCampanha).format('DD/MM/YYYY')}
                        readOnly
                      />
                      <Input
                        label="Pontuação atual"
                        value={form.pontuacao}
                        readOnly
                      />
                      <Input
                        label="Quantidade de faturas com pagamento total"
                        value={form.quantidadeTotalPaga}
                        readOnly
                      />
                      <Input
                        label="Quantidade de faturas com pagamento parcial"
                        value={form.quantidadeTotalParcial}
                        readOnly
                      />
                      <Input
                        label="Quantidade de faturas com pagamento parcelado"
                        value={form.quantidadeTotalParcelada}
                        readOnly
                      />
                    </Grid>
                  </ContainerFlex>
                </Collapse>

                <Collapse
                  collapseHeight={40}
                  title={
                    form.temCarteiraDigital
                      ? '7. Dados do Cadastro/Proposta'
                      : '6. Dados do Cadastro/Proposta'
                  }
                >
                  <ContainerFlex marginLeft={26} marginRight={26}>
                    <Grid xs={1} lg={2} gap={30}>
                      <ContainerFlex>
                        <Input
                          label="Origem do Cadastro/Proposta"
                          value={form.propOrigemDoCadastro.value}
                          readOnly
                        />

                        <Input
                          label="Loja do Cadastro/Proposta"
                          value={
                            isSenff
                              ? form.propLojaDoCadastro.value
                              : form.propOrigemDoCadastro.value !== 'LOJA'
                              ? form.propOrigemDoCadastro.value
                              : form.propLojaDoCadastro.value
                          }
                          readOnly
                        />
                        <Input
                          label="Status da proposta"
                          value={form.propStatus.value}
                          readOnly
                        />
                        <Input
                          label="Tipo de aprovação"
                          value={
                            form.propStatusAprovacao.value ===
                            'APROVADA_MESA_CREDITO'
                              ? 'MESA'
                              : 'AUTOMÁTICO'
                          }
                          readOnly
                        />
                      </ContainerFlex>

                      <ContainerFlex>
                        <Input
                          label="Operador(a) de Venda"
                          value={
                            form.propOrigemDoCadastro.value === 'SITE'
                              ? 'WEB'
                              : form.propDigitador.value
                          }
                          readOnly
                        />
                        <ContainerFlex row height={5}>
                          <ContainerFlex marginRight={16}>
                            <Input
                              label="Data Proposta"
                              value={moment(form.propDataCadastro.value).format(
                                'DD/MM/YYYY',
                              )}
                            />
                          </ContainerFlex>

                          <ContainerFlex>
                            <Input
                              label="Horário"
                              value={moment(form.propDataCadastro.value).format(
                                'HH:mm:ss',
                              )}
                            />
                          </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex row height={5}>
                          <ContainerFlex marginRight={16}>
                            <Input
                              label="Data Aprovação"
                              value={moment(form.dataAprovacao.value).format(
                                'DD/MM/YYYY',
                              )}
                            />
                          </ContainerFlex>

                          <ContainerFlex>
                            <Input
                              label="Horário"
                              value={moment(form.dataAprovacao.value).format(
                                'HH:mm:ss',
                              )}
                            />
                          </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex>
                          <Input
                            label="Responsável pela aprovação"
                            value={
                              form.propStatusAprovacao.value ===
                              'APROVADA_MESA_CREDITO'
                                ? form.propNomeOperador.value
                                : ''
                            }
                            readOnly
                          />
                        </ContainerFlex>
                      </ContainerFlex>
                    </Grid>
                  </ContainerFlex>
                </Collapse>
                <Collapse
                  collapseHeight={50}
                  title={
                    form.temCarteiraDigital
                      ? '8. Referências pessoais'
                      : '7. Referências pessoais'
                  }
                >
                  <ContainerFlex marginLeft={26} marginRight={26}>
                    <Grid xs={1} lg={2}>
                      <ContainerFlex>
                        <St.TitleCategory style={{ paddingLeft: '0px' }}>
                          Referência I
                        </St.TitleCategory>
                        <br />
                        <ContainerFlex marginRight={20}>
                          <Input
                            label="Nome completo"
                            name="nomeRefe"
                            value={addReference?.nomeRefe}
                            onChange={handleChangeReference}
                          />
                          <Input
                            label="Telefone"
                            name="phoneRefe"
                            value={addReference?.phoneRefe?.masked}
                            maxLength={15}
                            mask={MaskTypes.PHONE}
                            onChange={handleChangeReference}
                          />
                          <Select
                            label="Relação com o cliente"
                            value={addReference?.relacaoCli}
                            name="relacaoCli"
                            onChange={(val) => {
                              handleSelectReference(val, 'relacaoCli');
                            }}
                            options={RELATION_TYPES}
                          />
                          <Input
                            label="Comentário"
                            name="comentarioRef"
                            value={addReference?.comentarioRef}
                            onChange={handleChangeReference}
                          />
                        </ContainerFlex>
                      </ContainerFlex>

                      <ContainerFlex>
                        <St.TitleCategory style={{ paddingLeft: '5px' }}>
                          Referência II
                        </St.TitleCategory>
                        <br />
                        <ContainerFlex marginRight={20}>
                          <Input
                            label="Nome completo"
                            name="nomeRefe"
                            value={addReferenceII?.nomeRefe}
                            onChange={handleChangeReferenceII}
                          />
                          <Input
                            label="Telefone"
                            name="phoneRefe"
                            value={addReferenceII?.phoneRefe?.masked}
                            maxLength={15}
                            mask={MaskTypes.PHONE}
                            onChange={handleChangeReferenceII}
                          />
                          <Select
                            label="Relação com o cliente"
                            value={addReferenceII?.relacaoCli}
                            name="relacaoCli"
                            onChange={(val) => {
                              return handleSelectReferenceII(val, 'relacaoCli');
                            }}
                            options={RELATION_TYPES}
                          />
                          <Input
                            label="Comentário"
                            name="comentarioRef"
                            value={addReferenceII?.comentarioRef}
                            onChange={handleChangeReferenceII}
                          />
                        </ContainerFlex>
                      </ContainerFlex>
                    </Grid>
                  </ContainerFlex>
                  <CardView>
                    <ContainerFlex row end padding={24}>
                      <Button
                        marginLeft={16}
                        onClick={() => setDialogIsOpenSaveR(true)}
                      >
                        Salvar
                      </Button>
                    </ContainerFlex>
                  </CardView>
                </Collapse>
              </>
            )}

            <ContainerFlex row end marginTop={14}>
              <Button outlined onClick={goBack} fitContent>
                Voltar
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ConsultUserDetail;
