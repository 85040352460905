/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Input from '~/components/input';
import Layout from '~/components/layoutSignIn';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { IEmployee } from '~/models/employee';
import endpoints from '~/services/endpoints';
import { hideEmailMask } from '~/utils/masks';
import { encryptionAES_CBC } from '~/utils/crypt';
import * as St from './styles';

const Forgot: React.FC = () => {
  const [email, setEmail] = useState('');
  const [testDialog, setTextDialog] = useState('');
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialogFailed, setOpenDialogFailed] = useState(false);
  const [idUserCrypt, setIdUserCrypt] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const { state } = useLocation<IEmployee>();

  const RequestEmail = useApi<IEmployee>(
    `${endpoints.consult.searchEmailForgot}/${state.cpf}`,
    ApiMethod.GET,
  );

  const RequestSendEmail = useApi<IEmployee>(
    `${endpoints.register.sendEmailForgot}`,
    ApiMethod.POST,
  );

  useEffect(() => {
    async function getEmail() {
      const response = await RequestEmail.callApi();

      if (response.status === 200 || response.status === 201) {
        setEmail(response.data?.email);
        setIdUserCrypt(await handleCryptAES(response.data?.id));
      } else {
        setOpenDialogFailed(true);
        setTextDialog(response.data?.mensagem);
      }
    }
    getEmail();
  }, []);

  const confirmSuccess = () => {
    history.push('/');
    setOpenDialogSuccess(false);
  };

  const confirmFailed = () => {
    setOpenDialogFailed(false);
  };

  async function handleSubmit(evt) {
    evt.preventDefault();
    setLoading(true);

    try {
      const response = await RequestSendEmail.callApi({
        idUsuario: idUserCrypt,
      });

      if (response.status === 204) {
        setOpenDialogSuccess(true);
      } else {
        setOpenDialogFailed(true);
        setTextDialog(response.data?.mensagem);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const handleCryptAES = async (value: string): Promise<string> => {
    const aleatorio = (Math.random() + 1).toString(36).substr(2, 8);
    const date = new Date();
    const now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );

    const encryption = await encryptionAES_CBC(
      CryptoJS.enc.Latin1.parse('cBmT7UMdFQkL4ckQUeFU^pULW$Yo8!h^'),
      CryptoJS.enc.Latin1.parse(
        moment(new Date(now_utc)).format('MMyyyyDD') + aleatorio,
      ),
      value,
    );

    return aleatorio + encryption;
  };

  return (
    <>
      <Dialog
        icon={icons.IconTick}
        message="E-mail enviado com sucesso!"
        open={openDialogSuccess}
        positiveLabel="Confirmar"
        positiveAction={confirmSuccess}
      />
      <Dialog
        icon={icons.warning}
        message={testDialog}
        open={openDialogFailed}
        positiveLabel="Entendi"
        positiveAction={confirmFailed}
      />
      <Layout>
        <ContainerFlex center>
          <St.Title>Esqueci minha senha</St.Title>
          <St.Text>
            Orientações serão enviadas para o e-mail de cadastro abaixo:
          </St.Text>
          <St.Form>
            <Input
              name="email"
              id="email"
              type="text"
              label="E-mail"
              value={hideEmailMask(email)}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </St.Form>
          <St.ViewButton>
            <Button
              size="large"
              type="submit"
              fullWidth
              onClick={handleSubmit}
              loading={isLoading}
            >
              Continuar
            </Button>
          </St.ViewButton>
        </ContainerFlex>
      </Layout>
    </>
  );
};

export default Forgot;
