/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactCodeInput from 'react-code-input';
import { useHistory } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Input from '~/components/input';
import Layout from '~/components/layoutSignIn';
import { useAuth } from '~/hooks/contexts/useAuth';
import Constants from '~/utils/constants';
import { cpfMask, IDefaultReturn, verificationCodeMask } from '~/utils/masks';
import * as St from './styles';
import Endpoints from '~/services/endpoints';
import useApi, { ApiMethod } from '~/hooks/useApi';

const SignIn: React.FC = () => {
  const [cpf, setCpf] = useState<IDefaultReturn>({} as IDefaultReturn);
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [twoFactorAuthDialog, setTwoFactorAuthDialog] = useState(false);
  const [twoFactorAuthMessage, setTwoFactorAuthMessage] = useState('');
  const [codeModal, setCodeModal] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [key, setKey] = useState(1);
  const SendCode = useApi<any>(Endpoints.account.sendCode, ApiMethod.POST);

  const history = useHistory();
  const { signIn } = useAuth();

  const clearCodeModal = () => {
    setVerificationCode('');
  };

  useEffect(() => {
    localStorage.removeItem(Constants.TOKEN_KEY);
    localStorage.removeItem(Constants.TOKEN_BEARER);
    localStorage.removeItem(Constants.TOKEN_RECAPTCHA);
  }, []);

  useEffect(() => {
    if (verificationCode.length === 0) {
      setKey(key + 1);
    }
  }, [verificationCode]);

  const activeButtonLogin = useMemo(
    () => cpf.masked?.length === 14 && password.length === 6,
    [password, cpf],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      setLoading(true);
      const sessionId = uuidv4();
      localStorage.setItem(Constants.SESSION_ID, sessionId);
      try {
        const response = await signIn({ cpf: cpf.unmasked, password });
        if (response.status >= 200 && response.status < 300) {
          const user = localStorage.getItem(Constants.USER_ROLE).toString();
          if (
            user.slice(2, 5) !== 'SUP'.toString() &&
            user.slice(2, 5) !== 'ADM'.toString()
          ) {
            history.push('/invoice');
          } else {
            history.push('/homeDash');
          }
        } else if (response.status === 422) {
          setTwoFactorAuthDialog(true);
          setTwoFactorAuthMessage(response.message);
          clearCodeModal();
        } else {
          setTwoFactorAuthDialog(false);
          clearCodeModal();
          setCodeModal(false);
          localStorage.setItem(Constants.X_CODE, '');
          handleToggleDialog();
          setDialogMessage(response.mensagem);
        }
      } catch (err) {
        clearCodeModal();
        localStorage.setItem(Constants.SESSION_ID, '');
        localStorage.setItem(Constants.X_CODE, '');
        handleToggleDialog();
        setDialogMessage(err.message);
      }
      setLoading(false);
    },
    [history, signIn, cpf, password],
  );

  const handleSubmitWithoutEvent = async (): Promise<void> => {
    setLoading(true);
    const sessionId = uuidv4();
    localStorage.setItem(Constants.SESSION_ID, sessionId);
    try {
      const response = await signIn({ cpf: cpf.unmasked, password });
      clearCodeModal();
      if (response.status >= 200 && response.status < 300) {
        const user = localStorage.getItem(Constants.USER_ROLE).toString();
        if (
          user.slice(2, 5) !== 'SUP'.toString() &&
          user.slice(2, 5) !== 'ADM'.toString()
        ) {
          history.push('/invoice');
        } else {
          history.push('/homeDash');
        }
      } else if (response.status === 422) {
        setTwoFactorAuthDialog(true);
        setTwoFactorAuthMessage(response.message);
        setVerificationCode('');
        clearCodeModal();
      } else {
        setTwoFactorAuthDialog(false);
        clearCodeModal();
        setVerificationCode('');
        setCodeModal(false);
        localStorage.setItem(Constants.X_CODE, '');
        handleToggleDialog();
        setDialogMessage(response.mensagem);
      }
    } catch (err) {
      clearCodeModal();
      setCodeModal(false);
      localStorage.setItem(Constants.X_CODE, '');
      handleToggleDialog();
      setDialogMessage(err.message);
    } finally {
      setCodeModal(false);
      setLoading(false);
      clearCodeModal();
      setCpf({ masked: '', unmasked: '' });
      setPassword('');
    }
    clearCodeModal();
  };

  const handleSendWhatsAppCode = async () => {
    try {
      const response = await SendCode.callApi({
        cpf: cpf.unmasked,
      });
      if (response.status >= 200 && response.status < 300) {
        setCodeModal(true);
      } else {
        clearCodeModal();
        handleToggleDialog();
        setDialogMessage(response.mensagem);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTwoFactorAuthDialog(false);
    }
  };

  const handleToggleDialog = useCallback(() => {
    setDialogIsOpen((prev) => !prev);
  }, []);

  function handleForgotPass(event) {
    event.preventDefault();
    if (!cpf.masked) {
      setDialogMessage(
        'Favor informar o CPF, para continuar com a recuperação de senha.',
      );
      setError('Informe seu CPF');
      setDialogIsOpen(true);
    } else {
      setDialogMessage('');
      setError('');
      setDialogIsOpen(false);
      history.push('/forgot', { cpf: cpf.unmasked });
    }
  }

  return (
    <Layout>
      <St.Container>
        <St.Logo src={icons.logoLarge} alt="Logo TeuCard" />
        <St.Text>Faça seu login para ter acesso.</St.Text>
        <St.Form onSubmit={handleSubmit}>
          <ContainerFlex marginBottom={16}>
            <Input
              name="cpf"
              id="cpf"
              type="text"
              label="CPF"
              maxLength={14}
              value={cpf.masked}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
              errorMessage={error}
            />
          </ContainerFlex>

          <ContainerFlex marginBottom={16}>
            <Input
              name="password"
              id="password"
              type="password"
              label="Senha"
              maxLength={6}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </ContainerFlex>

          <St.Link onClick={handleForgotPass}>
            <span>Esqueci minha senha</span>
          </St.Link>
          <Button
            size="large"
            type="submit"
            fullWidth
            disabled={!activeButtonLogin || isLoading}
            active={activeButtonLogin}
            loading={isLoading}
          >
            Entrar
          </Button>
          <St.Version>{process.env.REACT_APP_VERSAO_APP}</St.Version>
        </St.Form>
        <Dialog
          icon={icons.warning}
          positiveLabel="Entendi"
          open={dialogIsOpen}
          message={dialogMessage}
          positiveAction={handleToggleDialog}
        />
        <Dialog
          icon={icons.warning}
          positiveLabel="Sim"
          negativeLabel="Não"
          open={twoFactorAuthDialog}
          message={twoFactorAuthMessage}
          positiveAction={() => handleSendWhatsAppCode()}
          negativeAction={() => setTwoFactorAuthDialog(false)}
          Loading={SendCode.loading}
        />
        <Dialog
          positiveLabel="Confirmar"
          open={codeModal}
          message="Insira o código de 6 dígitos enviado para o teu E-mail para prosseguir com o login!"
          positiveAction={() => {
            localStorage.setItem(
              Constants.X_CODE,
              verificationCode.replace(/ /g, ''),
            );
            handleSubmitWithoutEvent();
          }}
          Loading={isLoading}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 35,
            }}
          >
            <ReactCodeInput
              type="text"
              fields={6} // número de campos no código
              value={verificationCode}
              key={key}
              onChange={(e) => setVerificationCode(verificationCodeMask(e))}
              name="Código de verificação"
              inputMode="tel"
            />
          </div>
        </Dialog>
      </St.Container>
    </Layout>
  );
};

export default SignIn;
