/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CryptoJS from 'crypto-js';

export const decryptAES_CBC = async (
  key: string | CryptoJS.lib.WordArray,
  iv: any,
  data: string | CryptoJS.lib.CipherParams,
) => {
  const decrypted = CryptoJS.enc.Utf8.stringify(
    CryptoJS.AES.decrypt(data, key, {
      iv,
      padding: CryptoJS.pad.NoPadding,
      mode: CryptoJS.mode.CBC,
    }),
  ); // Message BarCode

  return decrypted;
};

export const encryptionAES_CBC = async (
  key: string | CryptoJS.lib.WordArray,
  iv: any,
  data: string | CryptoJS.lib.WordArray,
) => {
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return encrypted.toString();
};
