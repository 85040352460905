enum Constants {
  IP_ADRESS = '@NovaEra:ipAdress',
  TOKEN_KEY = '@NovaEra:token',
  TOKEN_BEARER = '@NovaEra:tokenBearer',
  USER = '@NovaEra:user',
  IS_LOGGED = 'isLogged',
  USER_ID = 'USER_ID',
  USER_ROLE = 'USER_ROLE',
  ROLE_OPERATOR = 'OPR',
  ROLE_RETAGUARDA = 'RET',
  ROLE_MESA_CREDITO = 'MDC',
  ROLE_SUPERVISOR = 'SUP',
  ROLE_ADMIN = 'ADM',
  ROLE_ATENDANCE = 'ATENDENTE',
  ROLE_ASSESSORIA = 'ASSESSORIA',
  ID_EMPRESTIMO = '@NovaEra:idEmprestimo',
  X_CODE = '@NovaEra:xCode',
  SESSION_ID = '@NovaEra:idSessao',
  CURRENT_EVENT = '@NovaEra:CurrentEvent',
  LIST_CHECKBOX = '@NovaEra:ListCheckbox',
  TOKEN_RECAPTCHA = '@NovaEra:TokenReCaptcha',
}

export default Constants;
