/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import styled from 'styled-components';
import { FaDownload, FaUpload, FaTrash } from 'react-icons/fa';
import { MdCropFree, MdSearch } from 'react-icons/md';
import Button from '../button';
import { Contract } from '~/services/consult/user/search/response';

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

const TableTitle = styled.caption`
  text-align: left;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 15px;
`;
type TableDataProps = {
  align?: string;
};
const TableHeader = styled.div<TableDataProps>`
  padding: 10px;
  font-weight: 700;
  background-color: #f1f3f9;
  text-align: ${({ align }) => align ?? 'initial'};
`;
const TableRow = styled.div<TableDataProps>`
  padding: 15px 10px;
  font-weight: 500;
  background-color: #f9fafd;
  text-align: ${({ align }) => align ?? 'initial'};
`;
type IconProps = {
  disabled: boolean;
};
const DownloadIcon = styled(FaDownload)<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;
const DeleteIcon = styled(FaTrash)<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;
const UploadIcon = styled(FaUpload)<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;
const ViewIcon = styled(MdCropFree)<IconProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

const SearchIcon = styled(MdSearch)<IconProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

type IDocumentItem = {
  title?: string;
  hidden?: boolean;
  handleClickUpload?: Function;
  isNotUpload?: boolean;
  data: {
    disabled: {
      download: boolean;
      view: boolean;
      upload?: boolean;
      delete?: boolean;
    };
    nome: string;
    onClickDownload?: Function;
    onClickView?: Function;
    onClickUpload?: Function;
    statusBeneficio?: string;
    onClickDelete?: Function;
    inVisible?: boolean;
  }[];
};

interface IDocumentsTable {
  data: Partial<IDocumentItem[]>;
  dataBenefit: Partial<any[]>;
  handleSelectOption: (selectedOption: any) => void;
  handleOpenDetails: (isOpen: boolean) => void;
}
const DocumentsTable: React.FC<IDocumentsTable> = ({
  data,
  dataBenefit,
  handleSelectOption,
  handleOpenDetails,
}) => {
  const dataBenefitFormatted = dataBenefit?.map((item) => {
    if (item.data?.[0]?.categoria === 'Platinum') {
      return {
        contratoAtual: item,
      };
    }
    return {
      contratoAtual: {
        ...item.contratoAtual,
      },
    };
  });

  return (
    <>
      {data?.map((e) => {
        if (e.hidden) return;

        const isGeneralDocuments =
          e.title === 'Documentos gerais' && !e.isNotUpload;
        const isBenefitDocuments = e.title === 'Documento dos benefícios';
        return !isBenefitDocuments ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: '10px',
              }}
            >
              <TableTitle>{e.title}</TableTitle>
              {isGeneralDocuments && (
                <Button
                  style={{ width: 25, height: 30, marginLeft: 10 }}
                  onClick={() => e.handleClickUpload()}
                  size="small"
                >
                  <UploadIcon disabled={false} />
                </Button>
              )}
            </div>
            <TableContainer>
              <TableHeader>Descrição</TableHeader>
              <TableHeader align="center">
                {isGeneralDocuments ? 'Excluir' : 'Download'}
              </TableHeader>
              <TableHeader align="center">Visualizar</TableHeader>
              {e?.data.map((item) => {
                const benefitIsCanceled = item?.statusBeneficio === 'CANCELADO';
                return (
                  !item.inVisible && (
                    <>
                      {item?.onClickUpload ? (
                        <>
                          <TableRow>
                            <div style={{ display: 'flex' }}>
                              <span
                                style={{
                                  flex: '0.8',
                                  color: benefitIsCanceled ? '#e4003a' : '#000',
                                }}
                              >
                                {item.nome}
                              </span>
                              <span onClick={() => item.onClickUpload()}>
                                <UploadIcon disabled={item.disabled?.upload} />
                              </span>
                            </div>
                          </TableRow>
                          <TableRow align="center">
                            <span>
                              <DownloadIcon
                                onClick={() => item.onClickDownload()}
                                disabled={item.disabled?.download}
                              />
                            </span>
                          </TableRow>
                          <TableRow align="center">
                            <ViewIcon
                              disabled={item.disabled.view}
                              onClick={() => item.onClickView()}
                            />
                          </TableRow>
                        </>
                      ) : (
                        <>
                          <TableRow
                            style={{
                              color: benefitIsCanceled ? '#e4003a' : '#000',
                            }}
                          >
                            {item.nome}
                          </TableRow>
                          {item.onClickDelete && (
                            <TableRow align="center">
                              <span>
                                <DeleteIcon
                                  onClick={() => item.onClickDelete()}
                                  disabled={item.disabled.delete}
                                />
                              </span>
                            </TableRow>
                          )}
                          {item.onClickDownload && (
                            <TableRow align="center">
                              <span>
                                <DownloadIcon
                                  onClick={() => item.onClickDownload()}
                                  disabled={item.disabled.download}
                                />
                              </span>
                            </TableRow>
                          )}

                          <TableRow align="center">
                            <ViewIcon
                              disabled={item.disabled.view}
                              onClick={() => item.onClickView()}
                            />
                          </TableRow>
                        </>
                      )}
                    </>
                  )
                );
              })}
            </TableContainer>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: '10px',
              }}
            >
              <TableTitle>{e.title}</TableTitle>
            </div>
            <TableContainer style={{ width: '100%' }}>
              <TableHeader>Descrição</TableHeader>
              <TableHeader />
              <TableHeader align="center">Detalhes</TableHeader>
            </TableContainer>
            {dataBenefitFormatted?.map(
              (item, index) =>
                item.contratoAtual.origemAdesao !== 'CENTRAL_EVENTOS' && (
                  <TableRow>
                    <div style={{ display: 'flex' }}>
                      <span
                        style={{
                          flex: '0.93',
                          color: '#000',
                        }}
                      >
                        {item?.contratoAtual?.nome ||
                          `Contrato ${item.contratoAtual?.data?.[0]?.categoria}`}
                      </span>
                      <span>
                        <SearchIcon
                          style={{ width: 20, height: 20 }}
                          disabled={false}
                          onClick={() => {
                            handleSelectOption(
                              item?.contratoAtual?.idBeneficioAutorizador ||
                                null,
                            );
                            handleOpenDetails(true);
                          }}
                        />
                      </span>
                    </div>
                  </TableRow>
                ),
            )}
          </>
        );
      })}
    </>
  );
};

export default DocumentsTable;
