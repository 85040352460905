/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import ContainerFlex from '../containerFlex';
import * as St from './styles';
import Input from '../newInput';

export interface IOption {
  readonly id?: string | number;
  value: string;
}

export type InputProps = {
  name?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  options: IOption[];
  value?: string;
  onChange?: (option: IOption) => void;
  onFocus?: (isOpen: boolean) => void;
  readOnly?: boolean;
  heightWhenOpen?: string;
  continerOptionsStyle?: React.CSSProperties;
};

const AutoCompleteSelect = ({
  label,
  value,
  errorMessage,
  onChange,
  readOnly,
  options = [],
  disabled,
  heightWhenOpen,
  continerOptionsStyle,
  defaultValue,
  onFocus,
}: InputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(() => options);
  const [selectedValue, setSelectedValue] = useState<string>(() => value);

  const [filterText, setFilterText] = useState('');
  const handleToggleFocus = () => {
    if (!readOnly) setIsFocused((prev) => !prev);
  };

  useEffect(() => {
    if (onFocus) {
      onFocus(isFocused);
    }
  }, [isFocused]);
  useEffect(() => {
    if (!value) setSelectedValue(null);
  }, [value]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleSelectValue = (value: IOption) => {
    onChange(value);
    setSelectedValue(value.value);
    setFilteredOptions(options);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedValue(null);
    if (!value.length) {
      setFilteredOptions(options);
      setFilterText('');
      return;
    }

    setFilterText(value);
    setFilteredOptions(
      options.filter((option) =>
        option.value
          .toLowerCase()
          .replaceAll(' ', '')
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .trim()
          .includes(value.toLocaleLowerCase().replaceAll(' ', '').trim()),
      ),
    );
  };
  return (
    <ContainerFlex
      onFocus={handleToggleFocus}
      onBlur={() => {
        if (!selectedValue && filterText) {
          setSelectedValue('');

          setFilterText('');
        }
        setIsFocused(false);
      }}
      row
      flex="0"
    >
      <St.Container disabled={disabled} isFocused={isFocused}>
        <ContainerFlex row space>
          <Input
            placeholder=""
            label={label}
            value={selectedValue}
            icon={FiChevronDown}
            defaultValue={value}
            onChange={handleFilter}
            autoComplete="none"
          />
        </ContainerFlex>

        <St.ContainerOptions
          heightWhenOpen={heightWhenOpen}
          isFocused={isFocused}
          style={{ ...continerOptionsStyle }}
        >
          <div className="scrollable">
            {filteredOptions.length > 0 ? (
              filteredOptions?.map((option) => (
                <button
                  type="button"
                  key={option.id}
                  data-option={option.value}
                  className="option"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelectValue(option);
                    setIsFocused(false);
                  }}
                >
                  {option?.value}
                </button>
              ))
            ) : (
              <div className="optionDisabled">Sem resultados</div>
            )}
          </div>
        </St.ContainerOptions>

        {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
      </St.Container>
    </ContainerFlex>
  );
};

export default AutoCompleteSelect;
