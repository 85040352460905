/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import ContainerFlex from '../containerFlex';
import * as St from './styles';
import Input from '../newInput';
import Checkbox from '../checkbox';
import Button from '../button';

export interface IOption {
  readonly id?: string | number;
  value: string;
}

export type InputProps = {
  name?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  options: IOption[];
  value?: IOption[];
  onChange?: (option: IOption[]) => void;
  onFocus?: (isOpen: boolean) => void;
  readOnly?: boolean;
  heightWhenOpen?: string;
  continerOptionsStyle?: React.CSSProperties;
  limitTag?: number;
  showChips?: boolean;
  closeWhenSelect?: boolean;
  btnClear?: boolean;
};
const Popover = ({ open, anchorEl, anchorOrigin, children }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [adjustedOrigin, setAdjustedOrigin] = useState(anchorOrigin);

  useEffect(() => {
    if (open && anchorEl) {
      const {
        top,
        left,
        width,
        height,
        right,
        bottom,
      } = anchorEl.getBoundingClientRect();
      console.log('ANCHOR EL PROPS', anchorEl.getBoundingClientRect());
      const popoverWidth = width; // Set a fixed width for the popover or calculate dynamically
      const popoverHeight = 200; // Set a fixed height for the popover or calculate dynamically
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Default position (no overflow)
      let newTop =
        anchorOrigin.vertical === 'bottom' ? height * 2 : -height * 2;
      let newLeft = anchorOrigin.horizontal === 'right' ? width : 0;
      let newOrigin = { ...anchorOrigin };

      // Check for overflow on the right and bottom, adjust if necessary
      if (newLeft + popoverWidth > viewportWidth) {
        newLeft = right - width;
        newOrigin.horizontal = 'left';
      }
      if (newTop + popoverHeight > viewportHeight) {
        newTop = 0;
        newOrigin.vertical = 'top';
      }
      console.log({ newTop, newLeft, newOrigin });
      setPosition({ top: newTop, left: newLeft });
      setAdjustedOrigin(newOrigin);
    }
  }, [open, anchorEl, anchorOrigin]);

  if (!open || !anchorEl) return null;

  return (
    <St.PopoverContainer style={position} anchorOrigin={adjustedOrigin}>
      {children}
    </St.PopoverContainer>
  );
};
const AutoCompleteSelectMultiple = ({
  label,
  value,
  errorMessage,
  onChange,
  readOnly,
  options = [],
  disabled,
  heightWhenOpen,
  continerOptionsStyle,
  limitTag = 3,
  onFocus,
  name,
  showChips = true,
  closeWhenSelect = false,
  btnClear = true,
}: InputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(() => options);
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(
    () => value,
  );

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const [filterText, setFilterText] = useState('');
  const handleToggleFocus = (e) => {
    e.stopPropagation();
    if (!readOnly) setIsFocused((prev) => !prev);
  };

  useEffect(() => {
    if (onFocus) {
      onFocus(isFocused);
    }
  }, [isFocused]);
  useEffect(() => {
    if (!value) setSelectedOptions([]);
  }, [value]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  console.log('selectedOptions', selectedOptions);
  const handleSelectValue = (value: IOption) => {
    setFilterText('');
    const found = selectedOptions.find((e) => e.value === value.value);
    console.log('FOUND', found);
    if (found) {
      const filtered = selectedOptions.filter((s) => s.value !== value.value);
      setSelectedOptions(filtered);
      onChange(filtered);
      return;
    }
    const newValue = [...selectedOptions, value];
    setSelectedOptions(newValue);
    onChange(newValue);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value.length) {
      setFilteredOptions(options);
      setFilterText('');
      return;
    }

    setFilterText(value);
    setFilteredOptions(
      options.filter((option) =>
        option.value
          .toLowerCase()
          .replaceAll(' ', '')
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .trim()
          .includes(value.toLocaleLowerCase().replaceAll(' ', '').trim()),
      ),
    );
  };
  const inputValue = `${selectedOptions
    .slice(0, limitTag)
    .map((e) => e.value.split(' ')[0])} `;

  // const inputValue =
  //   selectedOptions?.length > limitTag
  //     ? `${selectedOptions
  //         .slice(0, limitTag)
  //         .map((e) => e.value.split(' ')[0])} +${
  //         selectedOptions.length - limitTag
  //       }`
  //     : selectedOptions?.map((e) => e.value.split(' ')[0]).toString();
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen(!open);
  };
  console.log(isFocused);
  useEffect(() => {
    if (!isFocused) return;
    const handler = (e) => {
      console.log('CLICKED DOC', e);
      const target = e.target as HTMLDivElement;
      if (target.parentElement.className === 'autocomplete-options-container') {
        return;
      }

      setIsFocused(false);
    };
    document.addEventListener('click', handler);

    return () => document.removeEventListener('click', handler);
  }, [isFocused]);
  return (
    <ContainerFlex row flex="0">
      <St.Container disabled={disabled} isFocused={isFocused}>
        {!!label && <label htmlFor={name}>{label}</label>}
        <ContainerFlex
          className="inputContainer"
          row
          space
          style={{
            border: showChips ? '1px solid gray' : 'none',
          }}
        >
          {showChips ? (
            !!selectedOptions.length ? (
              <div className="chips">
                {inputValue?.split(',').map((e) => (
                  <span>{e}</span>
                ))}

                {selectedOptions.length > limitTag &&
                  `+${selectedOptions.length - limitTag}`}
              </div>
            ) : null
          ) : null}

          <input
            id="auto-complete-input"
            ref={anchorRef}
            // onFocus={handleToggleFocus}
            onClick={handleToggleFocus}
            placeholder="Pesquisar"
            name={name}
            type="text"
            onChange={handleFilter}
            disabled={disabled}
            value={filterText}
            autoComplete="none"
          />
          {btnClear && (
            <Button
              size="small"
              fitContent
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedOptions([]);
                onChange([]);
              }}
            >
              Limpar
            </Button>
          )}
          {/* <Input
            placeholder=""
            label={label}
            value={filterText}
            icon={FiChevronDown}
            onChange={handleFilter}
            autoComplete="none"
          /> */}
        </ContainerFlex>

        <St.ContainerOptions
          isFocused={isFocused}
          style={{ ...continerOptionsStyle }}
        >
          <div className="autocomplete-options-container">
            {filteredOptions.length > 0 ? (
              filteredOptions?.map((option) => (
                <div
                  className="autocomplete-option"
                  onClick={() => {
                    handleSelectValue(option);
                  }}
                >
                  <Checkbox
                    containerProps={{ style: { marginTop: 0 } }}
                    auto={false}
                    readOnly
                    value={selectedOptions?.some(
                      (e) => e.value === option.value,
                    )}
                  />
                  {option?.value}
                </div>
              ))
            ) : (
              <div className="optionDisabled">Sem resultados</div>
            )}
          </div>
        </St.ContainerOptions>
        {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
      </St.Container>
    </ContainerFlex>
  );
};

export default AutoCompleteSelectMultiple;
