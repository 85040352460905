/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input from '~/components/input';
import Loading from '~/components/loading';
import PictureCard from '~/components/pictureCard';
import TextArea from '~/components/textarea';
import TitleApp from '~/components/title';
import { IReducers } from '~/contexts/proposal';
import { useProposal } from '~/hooks/contexts/useProposal';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { IProposal, IReference, IReferenceII } from '~/models/proposal';
import ProposalReason from '~/models/proposal-reason';
import PropostaContatoReferencia from '~/models/proposta-contatos-ref';
import ConsultUserDetailResponse from '~/services/consult/user/detail/response';
import endpoints from '~/services/endpoints';
import { cpfMask, currencyMask, phoneMask } from '~/utils/masks';
import { ItemDialogReasonDivider } from './components/DialogReasonComponents';
import ProposalReference from './components/ProposalReference';
import ProposalReferenceII from './components/ProposalReferenceII';
import ProposalResult from './components/ProposalResult';
import ProposalResultSPC from './components/ProposalResultSPC';
import ReasonDialogItem from './components/ReasonDialogItem';
import * as St from './styles';
import IResponseGetUserDocuments from '~/services/files/getUserDocuments/response';
import Constants from '~/utils/constants';
import Container from '~/components/container';
import DocumentsTable from '~/components/DocumentsTable';

interface IErrors {
  parecer?: string;
  limitRoot?: string;
  limitRoot80Percent?: string;
}

interface IMotReanalise {
  celular: string;
  contatos: {
    comentario: string;
    data?: string;
    idProposta: string;
    nome: string;
    referencias: string;
    relacao: string;
    telefone: string;
  }[];
  decisao: string;
  endereco: {
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string;
    logradouro: string;
    numero: string;
    pais: string;
    uf: string;
  };
  rendaInformada: number;
  IdOperador?: string;
  idProposta?: string;
  justificativa?: string;
  idOperador?: string;
  limiteCredito?: number | string;
}

interface IDocumentsImage {
  income: {
    front: string;
    tipo?: string;
  };
  address: {
    front: string;
    tipo?: string;
  };
}

interface ITypeDocDeversos {
  file: string;
  filename: string;
}

const DetailProposalCT = () => {
  const [dialogMessageStatus, setDialogMessageStatus] = useState(false);
  const [dialogApproveStatus, setDialogApproveStatus] = useState(false);
  const [dialogRefuseStatus, setDialogRefuseStatus] = useState(false);
  const [dialogReasonShow, setDialogReasonShow] = useState(false);

  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogMessageGoBack, setDialogMessageGoBack] = useState(false);
  const [parecer, setParecer] = useState('');
  const [proposalSPC, setProposalSPC] = useState<IProposal>({} as IProposal);
  const [userInfo, setUserinfo] = useState<ConsultUserDetailResponse>();
  const [diaVenc, setDiaVenc] = useState('');
  const [errors, setErrors] = useState<IErrors>({} as IErrors);

  const { state } = useLocation<{ id: string; cpf: string }>();
  const history = useHistory();
  const {
    addReference,
    addReferenceII,
    proposal,
    reference,
    referenceII,
  } = useProposal();

  const [hasReference, setHasReference] = useState(false);
  const [hasReferenceII, setHasReferenceII] = useState(false);

  const [limitDefined, setLimitDefined] = useState<number | string>('0');
  const [isLimitValid, setIsLimitValid] = useState<boolean>(true);

  const [reasons, setReasons] = useState<ProposalReason[]>([]);
  const [showAprrovedButton, setShowAprrovedButton] = useState<boolean>(true);
  const [documentsImage, setDocumentsImage] = useState({} as IDocumentsImage);
  const [proposalDocuments, setProposalDocuments] = useState<
    ITypeDocDeversos[]
  >([]);

  const RequestUserInfo = useApi<ConsultUserDetailResponse>(
    endpoints.consult.detailUser.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const RequestUserDocuments = useApi(
    `${endpoints.consult.searchUserDocuments}${RequestUserInfo?.data?.propostaId}`,
    ApiMethod.GET,
  );

  const RequestUserDocumentsCPF = useApi<IResponseGetUserDocuments>(
    endpoints.consult.searchUserDocumentsCPF.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const RequestProposalSPC = useApi<any>(
    endpoints.consult.searchProposalSPC.replace('PARAM_ID_PROPOSTA', state?.id),
    ApiMethod.GET,
  );

  const GETSearchDocBase64 = useApi<any>(
    endpoints.files.getSearchDocBase64.replace('PARAM_ID_PROPOSTA', state?.id),
    ApiMethod.GET,
  );

  const getProposalBySPC = async () => {
    const responseSPC = await RequestProposalSPC.callApi();

    if (responseSPC.status === 200) {
      return responseSPC.data;
    }
    return null;
  };

  useEffect(() => {
    RequestUserInfo.callApi();
    popula();
    if (state?.id) {
      handleGetOtherDocuments();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.cartaoAtual.limiteCredito) {
      setLimitDefined(userInfo?.cartaoAtual.limiteCredito);
    }
  }, [userInfo]);

  useEffect(() => {
    if (RequestUserInfo.data) {
      setUserinfo(RequestUserInfo.data);
      RequestUserDocuments.callApi();
      RequestUserDocumentsCPF.callApi().then((response) => {
        isImageBase64(
          `data:image/png;base64, ${response.data?.documentoEndereco}`,
        ).then((data) => {
          setDocumentsImage((prev) => ({
            ...prev,
            address: {
              front:
                !!response.data?.documentoEndereco &&
                `${data ? 'data:image/png;base64' : ''}, ${
                  response.data?.documentoEndereco
                }`,
              tipo: data ? 'image' : 'pdf',
            },
          }));
        });

        isImageBase64(
          `data:image/png;base64, ${response.data?.documentoRenda}`,
        ).then((data) => {
          setDocumentsImage((prev) => ({
            ...prev,
            income: {
              front:
                !!response.data?.documentoRenda &&
                `${data ? 'data:image/png;base64' : ''}, ${
                  response.data?.documentoRenda
                }`,
              tipo: data ? 'image' : 'pdf',
            },
          }));
        });
      });
    }
  }, [RequestUserInfo.data]);

  const showOptionsRule = () => {
    const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
    const result = userRoles.includes('ADM');
    return result;
  };

  const popula = async () => {
    const dataProposalSPC = await getProposalBySPC();

    if (dataProposalSPC) {
      if (
        dataProposalSPC.status === 'APROVADA' ||
        dataProposalSPC.status === 'REPROVADA'
      ) {
        setShowAprrovedButton(false);
      }

      setDiaVenc(dataProposalSPC?.proposta?.diaVencimento);
      setReasons(dataProposalSPC?.motivoReanaliseResponse || []);
      setProposalSPC(dataProposalSPC);
      setLimitDefined(userInfo?.cartaoAtual?.limiteCredito);
      loadProposalContacts(dataProposalSPC.proposta.contatos || []);
    }
  };

  const apiMotReanaliseApro = useApi<any>(
    endpoints.register.proposalMotReanaliseApro,
    ApiMethod.PUT,
  );

  const apiMotReanaliseRepro = useApi<any>(
    endpoints.register.proposalMotReanaliseRepro,
    ApiMethod.PUT,
  );

  const isParecerValid = () => {
    if (!parecer.trim().length) {
      setErrors((prev) => ({
        ...prev,
        parecer: 'Por favor informe o parecer.',
      }));

      return false;
    }
    setErrors((prev) => ({
      ...prev,
      parecer: null,
    }));
    return true;
  };

  const onClickAprove = async () => {
    setDialogApproveStatus(false);
    if (!isParecerValid()) return;
    if (!checkLimitValid(limitDefined)) return;

    try {
      const payload = getMotReanalisePayload();

      const response = await apiMotReanaliseApro.callApi(payload);

      if (response.status === 200 || response.status === 204) {
        setDialogMessage('Proposta aprovada.');
        setDialogMessageGoBack(true);
      } else {
        setDialogMessage(response.mensagem);
        setDialogMessageGoBack(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDialogMessageStatus(true);
    }
  };

  const onClickRefuse = async () => {
    setDialogRefuseStatus(false);
    if (!isParecerValid()) return;

    try {
      const payload = getMotReanalisePayload();

      const response = await apiMotReanaliseRepro.callApi(payload);

      if (response.status === 200 || response.status === 204) {
        setDialogMessage('Proposta recusada.');
        setDialogMessageGoBack(true);
      } else {
        setDialogMessage(response.mensagem);
        setDialogMessageGoBack(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDialogMessageStatus(true);
    }
  };

  const getMotReanalisePayload = (): IMotReanalise => {
    const payload: IMotReanalise = {
      celular: proposal.telefone,
      contatos: [
        {
          comentario: reference.reference?.comentarioRef,
          idProposta: state?.id,
          nome: reference.reference?.nomeRefe,
          referencias: '',
          relacao: reference.reference?.relacaoCli,
          telefone: reference.reference?.phoneRefe?.masked,
        },
        {
          comentario: referenceII.referenceII?.comentarioRefII
            ? referenceII.referenceII?.comentarioRefII
            : undefined,
          idProposta: state?.id,
          nome: referenceII.referenceII?.nomeRefeII
            ? referenceII.referenceII?.nomeRefeII
            : undefined,
          referencias: '',
          relacao: referenceII.referenceII?.relacaoCliII
            ? referenceII.referenceII?.relacaoCliII
            : undefined,
          telefone: referenceII.referenceII?.phoneRefeII?.masked
            ? referenceII.referenceII?.phoneRefeII?.masked
            : undefined,
        },
      ],

      decisao: 'APROVADA',
      endereco: {
        bairro: proposal.endereco?.bairro,
        cep: proposal.endereco?.cep,
        cidade: proposal.endereco?.cidade,
        complemento: proposal.endereco?.complemento,
        logradouro: proposal.endereco?.logradouro,
        numero: proposal.endereco?.numero,
        pais: proposal.endereco?.pais,
        uf: proposal.endereco?.uf,
      },
      rendaInformada: proposal.rendaInformada,
      idProposta: state?.id,
      justificativa: parecer,
      idOperador: localStorage.getItem('USER_ID'),
      limiteCredito: limitDefined,
    };

    return payload;
  };

  const loadProposalContacts = (items: PropostaContatoReferencia[] = []) => {
    let idx = 1;

    addReference(null);
    addReferenceII(null);
    setHasReference(false);

    if (items.length === 0) return;

    items.forEach((i) => {
      if (idx === 1) {
        const newRef: IReference = {
          id: i.id,
          comentarioRef: i.comentario,
          nomeRefe: i.nome,
          phoneRefe: phoneMask(i.telefone),
          relacaoCli: i.relacao,
        };

        const field: IReducers = {
          reference: newRef,
        };

        addReference(field);
        setHasReference(true);
      } else {
        const newRef: IReferenceII = {
          id: i.id,
          comentarioRefII: i.comentario,
          nomeRefeII: i.nome,
          phoneRefeII: phoneMask(i.telefone),
          relacaoCliII: i.relacao,
        };

        const field: IReducers = { referenceII: newRef };

        addReferenceII(field);
        setHasReferenceII(true);
      }

      idx += 1;
    });
  };

  const goBack = () => history.goBack();

  const closeModalMessage = () => {
    setDialogMessageStatus(false);
    if (dialogMessageGoBack) goBack();
  };

  const onChangeLimitDefined = (valueStr) => {
    const valueUn = Number(valueStr);

    if (valueUn.toString() === 'NaN' || valueUn < 0) {
      checkLimitValid(0);
      return setLimitDefined(0);
    }

    checkLimitValid(valueUn);
    setLimitDefined(valueUn);
  };

  const checkLimitValid = (limitFormatted) => {
    // novo limite só pode ser no máximo +80% do limite atual
    const limitRoot = Number(userInfo?.cartaoAtual?.limiteCredito) || 0.0;
    const root80Percent = limitRoot > 0 ? (limitRoot * 80) / 100 : 0.0;
    const limitRoot80Percent = limitRoot + root80Percent;
    let currentIsValidLimit = true;

    if (limitFormatted < limitRoot) {
      setErrors((prev) => ({
        ...prev,
        limitRoot: 'Limite não por ser inferior ao limite atual',
      }));

      currentIsValidLimit = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        limitRoot: '',
      }));
    }

    if (limitFormatted > limitRoot80Percent) {
      setErrors((prev) => ({
        ...prev,
        limitRoot80Percent: 'Limite definido maior que 80% do atual',
      }));

      currentIsValidLimit = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        limitRoot80Percent: '',
      }));
    }

    setIsLimitValid(currentIsValidLimit);
    return currentIsValidLimit;
  };

  const isImageBase64 = (str) =>
    new Promise((resolve) => {
      const img = new Image();
      img.addEventListener('error', () => resolve(false));
      img.addEventListener('load', () => resolve(/^data:.*;base64,/.test(str)));
      img.src = str;
    });

  function base64ToBlob(base64, type = 'application/octet-stream') {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type });
  }

  const handleOpenImageInNewTab = (url: string) => {
    const w = window.open('');
    const image = new Image();
    image.src = url;
    setTimeout(() => {
      w?.document && w?.document.write(image.outerHTML);
    }, 0);
  };

  const handleOpenPdfInNewTab = (base64: string) => {
    const blob = base64ToBlob(base64, 'application/pdf');
    const url = URL.createObjectURL(blob);
    const pdfWindow = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${url}'></iframe>`,
    );
  };

  const dataDocuments = useMemo(
    () => [
      {
        title: 'Documentos gerais',
        isNotUpload: true,
        data:
          proposalDocuments?.length === 0
            ? []
            : proposalDocuments?.map((e) => ({
                disabled: {
                  download: true,
                  view: false,
                },
                nome: e.filename,
                onClickView: () => {
                  handleDownloadDocument(e?.file, e?.filename);
                },
              })),
      },
    ],
    [proposalDocuments],
  );

  const handleDownloadDocument = useCallback(
    async (file: string, fileName: string) => {
      const aux = fileName.split('.')[1];
      switch (aux) {
        case 'jpg':
          handleOpenImageInNewTab(`data:image/jpg;base64, ${file}`);
          return;
        case 'png':
          handleOpenImageInNewTab(`data:image/png;base64, ${file}`);
          return;
        case 'jpeg':
          handleOpenImageInNewTab(`data:image/jpeg;base64, ${file}`);
          return;
        default:
          handleOpenPdfInNewTab(file);
      }
    },
    [],
  );

  /**
   * Busca os documentos divesos.
   */
  const handleGetOtherDocuments = () => {
    GETSearchDocBase64.callApi().then((data) => {
      setProposalDocuments(data?.data || []);
    });
  };

  return (
    <>
      <Loading
        status={
          apiMotReanaliseApro.loading ||
          apiMotReanaliseRepro.loading ||
          RequestProposalSPC.loading ||
          RequestUserInfo.loading ||
          RequestUserDocuments.loading ||
          RequestUserDocumentsCPF.loading
        }
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogMessageStatus}
        positiveLabel="Entendi"
        positiveAction={closeModalMessage}
        message={dialogMessage}
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogApproveStatus}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogApproveStatus(false)}
        positiveLabel="Aprovar"
        positiveAction={onClickAprove}
        message="Você tem certeza que deseja aprovar a proposta?"
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogRefuseStatus}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogRefuseStatus(false)}
        positiveLabel="Recusar"
        positiveAction={onClickRefuse}
        message="Você tem certeza que deseja recusar a proposta?"
      />

      <Dialog
        open={dialogReasonShow}
        positiveLabel="Fechar"
        widthInPercent={60}
        positiveAction={() => setDialogReasonShow(false)}
      >
        {reasons && (
          <ContainerFlex center>
            <h2>Motivos</h2>
            <br />
            {reasons.reverse().map((item: ProposalReason) => (
              <ContainerFlex row marginBottom={18}>
                <div>
                  <strong>CPF: </strong>
                  {cpfMask(state?.cpf).masked.trim()}
                </div>

                <ItemDialogReasonDivider />

                <div>
                  <strong>STATUS: </strong>
                  {item.decisao.trim()}
                </div>

                <ItemDialogReasonDivider />

                <div>
                  <strong>OPERADOR: </strong>
                  {item.operador.trim()}
                </div>

                <ItemDialogReasonDivider />

                <div>
                  <strong>DATA: </strong>
                  {String(
                    moment(item.dataInclusao).format('DD/MM/YYYY HH:mm'),
                  ).trim()}
                </div>

                <ItemDialogReasonDivider />

                <div>
                  <strong>MOTIVO: </strong>
                  {item.justificativa.trim()}
                </div>
              </ContainerFlex>
            ))}
          </ContainerFlex>
        )}
      </Dialog>

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <ContainerFlex padding={26}>
              <TitleApp>Reanálise de Limite - Decisão</TitleApp>
            </ContainerFlex>

            <ContainerFlex>
              <Grid xs={1} lg={2}>
                <ContainerFlex>
                  <St.TitleCategory paddingLeft={26}>
                    1. Dados do Cadastro / Proposta
                  </St.TitleCategory>
                  <br />

                  <br />
                  <ProposalResult data={proposalSPC} />
                </ContainerFlex>

                <ContainerFlex>
                  <St.TitleCategory paddingLeft={20}>
                    2. Resultado
                  </St.TitleCategory>
                  <br />

                  <br />
                  <ProposalResultSPC data={proposalSPC} />
                </ContainerFlex>
              </Grid>

              <ContainerFlex row spaceAround wrap>
                <ContainerFlex center horizontal>
                  <ContainerFlex row center horizontal>
                    <St.TitleFileChoose>CNH ou RG</St.TitleFileChoose>
                    <St.TitleFileChooseSmall>
                      (Frente e verso)
                    </St.TitleFileChooseSmall>
                  </ContainerFlex>

                  <ContainerFlex row center horizontal>
                    <PictureCard
                      width="150"
                      height="100"
                      showOptions={!!RequestUserDocuments.data?.documentoFrente}
                      src={
                        RequestUserDocuments.data?.documentoFrente
                          ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoFrente}`
                          : icons.tableDocumentFront
                      }
                    />
                    <PictureCard
                      width="150"
                      height="100"
                      showOptions={!!RequestUserDocuments.data?.documentoVerso}
                      src={
                        RequestUserDocuments.data?.documentoVerso
                          ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoVerso}`
                          : icons.tableDocumentBack
                      }
                    />
                  </ContainerFlex>

                  <St.TitleFileChooseSmall>
                    Clique no ícone para selecionar a imagem
                  </St.TitleFileChooseSmall>
                </ContainerFlex>

                <ContainerFlex center horizontal>
                  <ContainerFlex center horizontal>
                    <St.TitleFileChoose>
                      Comprovante de Renda
                    </St.TitleFileChoose>
                  </ContainerFlex>

                  <ContainerFlex row center horizontal>
                    <PictureCard
                      width="150"
                      height="100"
                      tipo={documentsImage.income?.tipo}
                      expand={!!documentsImage.income?.front}
                      onClickExpandPDF={() => {
                        const pdfWindow = window.open('');
                        pdfWindow.document.write(
                          `<iframe width='100%' height='100%' frameBorder='0' marginwidth='0' marginheight='0' src='data:application/pdf;base64 ${documentsImage.income?.front}'></iframe>`,
                        );
                      }}
                      showOptions={
                        !!RequestUserDocumentsCPF.data?.documentoRenda
                      }
                      src={
                        documentsImage.income?.tipo === 'image'
                          ? documentsImage.income?.front || icons.document
                          : icons.contract
                      }
                    />
                  </ContainerFlex>

                  <St.TitleFileChooseSmall>
                    Clique no ícone para selecionar a imagem
                  </St.TitleFileChooseSmall>
                </ContainerFlex>

                <ContainerFlex center horizontal>
                  <ContainerFlex center horizontal>
                    <St.TitleFileChoose>
                      Comprovante de Endereço
                    </St.TitleFileChoose>
                  </ContainerFlex>

                  <ContainerFlex row center horizontal>
                    <PictureCard
                      width="150"
                      height="100"
                      tipo={documentsImage.address?.tipo}
                      expand={!!documentsImage.address?.front}
                      onClickExpandPDF={() => {
                        const pdfWindow = window.open('');
                        pdfWindow.document.write(
                          `<iframe width='100%' height='100%' frameBorder='0' marginwidth='0' marginheight='0' src='data:application/pdf;base64 ${documentsImage.address?.front}'></iframe>`,
                        );
                      }}
                      showOptions={
                        !!RequestUserDocumentsCPF.data?.documentoEndereco
                      }
                      src={
                        documentsImage.address?.tipo === 'image'
                          ? documentsImage.address?.front || icons.document
                          : icons.contract
                      }
                    />
                  </ContainerFlex>

                  <St.TitleFileChooseSmall>
                    Clique no ícone para selecionar a imagem
                  </St.TitleFileChooseSmall>
                </ContainerFlex>
              </ContainerFlex>

              <ContainerFlex marginLeft={26} marginRight={26}>
                <DocumentsTable
                  data={dataDocuments}
                  dataBenefit={null}
                  handleSelectOption={null}
                  handleOpenDetails={null}
                />
              </ContainerFlex>
            </ContainerFlex>

            <br />

            <St.TitleCategory paddingLeft={26}>
              3. Referências Pessoais
            </St.TitleCategory>

            <ContainerFlex padding={26}>
              <Grid xs={1} lg={2}>
                <ContainerFlex>
                  <St.TitleCategory>Referência I</St.TitleCategory>
                  <br />
                  <ProposalReference hasReference={hasReference} />
                </ContainerFlex>

                <ContainerFlex>
                  <St.TitleCategory paddingLeft={10}>
                    Referência II
                  </St.TitleCategory>
                  <br />
                  <ProposalReferenceII hasReference={hasReferenceII} />
                </ContainerFlex>
              </Grid>
            </ContainerFlex>

            <br />

            <St.TitleCategory paddingLeft={26}>
              4. Proposta de Crédito
            </St.TitleCategory>

            <ContainerFlex padding={26}>
              <Grid xs={1} lg={2} gap={30}>
                <ContainerFlex>
                  <Input
                    label="ID da proposta"
                    value={proposalSPC?.spc ? proposalSPC?.idProposta : ''}
                  />
                  <Input
                    label="Restrição"
                    value={
                      // eslint-disable-next-line no-nested-ternary
                      proposalSPC?.spc
                        ? proposalSPC?.spc.negativacao
                          ? 'Sim'
                          : 'Não'
                        : ''
                    }
                  />
                  <Input
                    label="Score"
                    value={
                      proposalSPC?.spc ? String(proposalSPC?.spc.score) : ''
                    }
                  />
                  <Input
                    label="Limite definido"
                    value={limitDefined}
                    defaultValue={userInfo?.cartaoAtual?.limiteCredito}
                    onChange={(e) => {
                      showOptionsRule()
                        ? onChangeLimitDefined(e.target.value)
                        : null;
                    }}
                    errorMessage={errors.limitRoot || errors.limitRoot80Percent}
                    className="bold"
                    edit={showOptionsRule()}
                    disabled={!showOptionsRule()}
                  />
                </ContainerFlex>

                <ContainerFlex>
                  <Input
                    label="Dia vencimento da fatura"
                    value={diaVenc}
                    maxLength={2}
                    onChange={(e) => setDiaVenc(e.target.value)}
                  />
                  <Input
                    label="Valor da restrição"
                    value={
                      proposalSPC?.spc
                        ? currencyMask(proposalSPC?.spc.valorRestricao)
                        : ''
                    }
                  />

                  <div>
                    <Grid xs={1} lg={2} gap={10}>
                      <ContainerFlex>
                        <Input
                          label="Loja"
                          value={
                            proposalSPC?.spc
                              ? proposalSPC?.proposta.origem !== 'LOJA'
                                ? proposalSPC?.proposta.origem
                                : proposalSPC?.proposta.empresa
                              : ''
                          }
                        />
                      </ContainerFlex>

                      <ContainerFlex>
                        <Input
                          label="Operador(a) de Venda"
                          value={
                            proposalSPC?.spc
                              ? proposalSPC?.proposta.origem !== 'LOJA'
                                ? proposalSPC?.proposta.origem
                                : proposalSPC.proposta?.atendente?.nomeOperador
                              : ''
                          }
                        />
                      </ContainerFlex>
                    </Grid>
                    <Input
                      label="Limite atual"
                      value={currencyMask(userInfo?.cartaoAtual?.limiteCredito)}
                    />
                  </div>
                </ContainerFlex>
              </Grid>
            </ContainerFlex>

            <St.TitleCategory paddingLeft={26}>
              5. Histórico de Parecer
            </St.TitleCategory>

            {reasons && reasons.length > 0 && (
              <ContainerFlex marginLeft={22}>
                {reasons.length > 1 ? (
                  <>
                    <ContainerFlex row center>
                      <ReasonDialogItem item={reasons[0]} />
                    </ContainerFlex>
                    <ContainerFlex row center>
                      <Button
                        fitContent
                        onClick={() => setDialogReasonShow(true)}
                      >
                        Ver mais
                      </Button>
                    </ContainerFlex>
                  </>
                ) : reasons.length === 1 ? (
                  <ReasonDialogItem item={reasons[reasons.length - 1]} />
                ) : null}
              </ContainerFlex>
            )}

            {showAprrovedButton && (
              <>
                <St.TitleCategory paddingLeft={26}>
                  6. Decisão <St.TitleAbbr title="Obrigatório">*</St.TitleAbbr>
                </St.TitleCategory>

                <ContainerFlex padding={26}>
                  <TextArea
                    placeholder="Digitar parecer.."
                    name="parecer"
                    rows={10}
                    value={parecer}
                    onChange={(e) => setParecer(e.target.value)}
                    errorMessage={errors.parecer}
                    disabled={!showOptionsRule()}
                  />
                  <div style={{ marginTop: '10px' }}>
                    <St.TitleAbbrFooter>
                      * Obrigatório preencher
                    </St.TitleAbbrFooter>
                  </div>
                </ContainerFlex>
              </>
            )}

            <br />

            <ContainerFlex row horizontal padding={24} end>
              <Button outlined onClick={goBack}>
                Voltar
              </Button>

              {showAprrovedButton && (
                <>
                  <Button
                    outlined
                    marginLeft={16}
                    onClick={() => setDialogRefuseStatus(true)}
                    disabled={parecer.length > 0 ? !showOptionsRule() : true}
                  >
                    Recusar
                  </Button>

                  <Button
                    marginLeft={16}
                    onClick={() => setDialogApproveStatus(true)}
                    disabled={parecer.length > 0 ? !showOptionsRule() : true}
                  >
                    Aprovar
                  </Button>
                </>
              )}
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default DetailProposalCT;
